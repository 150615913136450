import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { userModule } from '@/store/user/module'
import { isPromise } from '@/store/utils'

const getUserExamMetadata = () => {
    const userExamMetadata = userExamMetadataModule.state.userExamMetadata.value
    if (isPromise(userExamMetadata)) {
        return []
    } else {
        return userExamMetadata
    }
}

const getUserExamMetadataForObjectId = (objectId: string) => {
    return getUserExamMetadata().find(uem => uem.objectId === objectId)
}

const getUserExamMetadataForExamGuid = (examGuid: string) => {
    return getUserExamMetadata().find(uem => uem.examGuid === examGuid)
}

const getCurrentUserExamMetadata = () => {
    const uems = userExamMetadataModule.getters.getUserExamMetadata()

    const currentUEMPointer = userModule.state.user?.currentUserExamMetadata
    const currentUEMFromPointer = uems.find(uem => uem.objectId === currentUEMPointer?.objectId) || null
    if (currentUEMFromPointer) {
        return currentUEMFromPointer
    }

    // Also still check currentExamGuid for now, for legacy UEM records
    const examGuid = userModule.state.user?.currentExamGuid
    const currentUEMFromExamGuid = examGuid && uems.find(uem => uem.examGuid === examGuid)

    return currentUEMFromExamGuid || null
}

const getFlaggedQuestions = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.flaggedQuestions || []
}

const getLevelUpProgress = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.levelUpProgress || []
}

const getMessageReferralConfig = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.messageConfig?.referral
}

export default {
    getUserExamMetadata,
    getUserExamMetadataForObjectId,
    getUserExamMetadataForExamGuid,
    getCurrentUserExamMetadata,
    getFlaggedQuestions,
    getLevelUpProgress,
    getMessageReferralConfig,
}
