import type { Study } from '@pocketprep/types'
import { Parse, runCloudFunction } from '@/store/parseUtils'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { userModule } from '@/store/user/module'
import { progressModule } from '@/store/progress/module'
import { analyticsModule } from '@/store/analytics/module'
import { examMetadataModule } from '@/store/examMetadata/module'

const deleteUserExamMetadata = async (userExamMetadata: Study.Class.UserExamMetadataJSON) => {
    const uem = await new Parse.Query<Study.Class.UserExamMetadata>('UserExamMetadata').get(userExamMetadata.objectId)
    await uem.destroy()
    await fetchUserExamMetadata(true)
}

const resetExamProgress = async (examMetadata: Study.Class.ExamMetadataJSON) => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (!uem) {
        throw new Error('No UserExamMetadata found.')
    }
    await runCloudFunction<Study.Cloud.resetQuizProgress>('resetQuizProgress', {
        examGuid: examMetadata.examGuid,
        examMetadataId: examMetadata.objectId,
    })
    resetLoadable(userModule.state.userData)
    resetLoadable(userExamMetadataModule.state.userExamMetadata)
    await userModule.actions.fetchUserData()
    
    // if this is an exam you aren't actively studying, reset and refetch all progress
    if (uem.examGuid !== examMetadata.examGuid) {
        resetLoadable(progressModule.state.progressByExamGuid)
        await progressModule.actions.fetchProgress()
    }
    
    analyticsModule.actions.updateIntercom()
}

export type TUpsertUserExamMetadata = 
    (Partial<Study.Class.UserExamMetadataPayload> & { objectId: string }) 
    | (Omit<Study.Class.UserExamMetadataPayload, 'user'> & { user?: Study.Class.User | Parse.Pointer })
const upsertUserExamMetadata = async (
    params: TUpsertUserExamMetadata, 
    options?: { forceInsert?: boolean }
) => {
    // Need to ensure Parse.User.current() is up to date
    await userModule.actions.refreshParseUser()
    const currentUser = Parse.User.current()

    if (!currentUser) {
        throw new Error('No user found.')
    }

    // if no objectId is passed, check that user does not already have a UEM record for that examGuid
    let uem: Study.Class.UserExamMetadataJSON | undefined
    if (!options?.forceInsert && !params.objectId && params.examGuid) {
        uem = userExamMetadataModule.getters.getUserExamMetadataForExamGuid(params.examGuid)
        params.objectId = uem?.objectId
    }
    
    const updateUserExamMetadata = new Parse.Object<TUpsertUserExamMetadata>('UserExamMetadata', {
            ...params,
            user: currentUser as Study.Class.User,
        }),
        userACL = new Parse.ACL(currentUser)

    updateUserExamMetadata.setACL(userACL)

    await updateUserExamMetadata.save()

    await fetchUserExamMetadata(true)

    analyticsModule.actions.updateIntercom()
}

const setScheduledExamDate = async (params: {
    scheduledExamDate: Date | undefined
    examNativeName: string
    uemCreatedAt: string
    uemObjectId: string
    bundle: Study.Class.BundleJSON
    hasSubscription: boolean

}) => {
    return upsertUserExamMetadata({
        objectId: params.uemObjectId,
        scheduledExamDate: params.scheduledExamDate,
    })
}

const fetchUserExamMetadata = async (forceFetch?: boolean) => {
    const currentUser = userModule.state.user

    if (currentUser) {
        await fetchLoadable(userExamMetadataModule.state.userExamMetadata, async () => {
            return runCloudFunction<Study.Cloud.fetchUserExamMetadata>('fetchUserExamMetadata')
        }, forceFetch)
    }
}

const toggleQuestionFlag = async (serial?: string) => {
    if (!serial) {
        throw new Error('Unable to flag question without a serial')
    }

    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (!uem) {
        throw new Error('No UserExamMetadata found.')
    }

    const parseUEM = new Parse.Object('UserExamMetadata')
    parseUEM.set('objectId', uem.objectId)

    const currentFlaggedQs = uem.flaggedQuestions || []
    if (currentFlaggedQs.includes(serial)) {
        parseUEM.remove('flaggedQuestions', serial)
    } else {
        parseUEM.addUnique('flaggedQuestions', serial)
    }

    await parseUEM.save()
    await fetchUserExamMetadata(true)
}

const startNewLevels = async ({ subjectIds }: {
    subjectIds: string[]
}) => {
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()

    if (currentExam) {
        const levelUpProgress = await runCloudFunction<Study.Cloud.startNewLevels>('startNewLevels', {
            examGuid: currentExam.examGuid,
            majorVersion: currentExam.version.split('.')[0],
            subjectIds,
        })

        await fetchUserExamMetadata(true)
    
        return levelUpProgress
    }
}

const resetLevelUpProgress = async () => {
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const examGuid = currentExam?.examGuid
    const majorVersion = currentExam?.version.split('.')[0]
    const examMetadataId = currentExam?.objectId
    if (examGuid && majorVersion) {
        await runCloudFunction<Study.Cloud.resetLevelUpProgress>('resetLevelUpProgress', {
            examGuid,
            examMetadataId,
            majorVersion,
        })

        await fetchUserExamMetadata(true)
    }
}

export default {
    upsertUserExamMetadata,
    setScheduledExamDate,
    fetchUserExamMetadata,
    resetExamProgress,
    deleteUserExamMetadata,
    toggleQuestionFlag,
    startNewLevels,
    resetLevelUpProgress,
}
