import { runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { fetchLoadable } from '@/store/utils'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { examMetadataModule } from '@/store/examMetadata/module'

const fetchGlobalQuestionMetrics = async (forceFetch?: boolean) => {
    await userModule.actions.fetchUserData()

    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()

    if (!currentExam) {
        return
    }

    await fetchLoadable(globalQuestionMetricModule.state.globalQuestionMetricsBySerial, async () => {
        const metrics = await runCloudFunction<Study.Cloud.fetchGlobalQuestionMetrics>('fetchGlobalQuestionMetrics', {
            examGuid: currentExam.examGuid,
            examMetadataId: currentExam.objectId,
        })

        return metrics.reduce<{ [serial: string]: Study.Class.GlobalQuestionMetricJSON }>((acc, metric) => {
            acc[metric.questionSerial] = metric
            return acc
        }, {})
    }, forceFetch)
}

export default {
    fetchGlobalQuestionMetrics,
}
