<template>
    <Portal to="modal">
        <ModalContainer
            key="explainYourAnswerFeedbackSurveyModal"
            :is-dark-mode="isDarkMode"
            :show-close-button="true"
            @close="emitClose"
        >
            <div class="eya-feedback-survey">
                <div class="eya-feedback-survey__title-section" v-dark="isDarkMode">
                    <div
                        class="eya-feedback-survey__title"
                    >
                        AI-Generated Feedback
                    </div>
                    <div
                        class="eya-feedback-survey__text"
                        v-dark
                    >
                        We gave you feedback after you explained your answer in this quiz.
                        We'd love to hear what you thought.
                    </div>
                </div>
                <div class="eya-feedback-survey__form">
                    <PocketTextarea
                        v-model="feedback"
                        label="What did you find helpful or unhelpful about the feedback you received?"
                        class="eya-feedback-survey__field eya-feedback-survey__field-input"
                        :is-dark-mode="isDarkMode"
                        height="108"
                        @keydown.enter="submitForm"
                    />
                </div>
                <div class="eya-feedback-survey__actions">
                    <PocketButton
                        class="eya-feedback-survey__dismiss-btn"
                        :is-dark-mode="isDarkMode"
                        @click="emitClose"
                        type="secondary"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        class="eya-feedback-survey__submit-btn"
                        :disabled="isSubmitDisabled"
                        :is-dark-mode="isDarkMode"
                        @click="submitForm"
                    >
                        Submit
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
import { toastModule } from '@/store/toast/module'

export const localStorageEYAFeedbackKey = 'eyaFeedback'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        PocketButton: UIKit.Button,
        Modal: UIKit.Modal,
        PocketTextarea: UIKit.Textarea,
        Radio: UIKit.Radio,
    },
})
export default class ExplainYourAnswerFeedbackSurvey extends Vue {
    feedback = ''
    eyaFeedback = JSON.parse(localStorage.getItem(localStorageEYAFeedbackKey) || '{}')

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get isSubmitDisabled () {
        return !this.feedback
    }

    mounted () {
        userModule.actions.updateWebConfig({
            hasSeenAIFeedbackSurveyModal: true,
        })
        localStorage.removeItem(localStorageEYAFeedbackKey)
    }

    submitForm () {
        analyticsModule.actions.amplitudeTrack('Explain Your Answer Feedback Survey Answered', {
            feedback: this.feedback,
            eyaFeedbackHelpfulCount: this.eyaFeedback.helpfulCount,
            eyaFeedbackUnhelpfulCount: this.eyaFeedback.unhelpfulCount,
        })
        toastModule.actions.displayToast({
            title: 'Thank you for your feedback!',
        })
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.eya-feedback-survey {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        margin: 0 auto;
        width: 100%;
    }

    &__title-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 36px 31px 0;
        box-sizing: border-box;

        &--dark {
            border-color: $ash;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 16px;
        letter-spacing: 0.3px;

        &--explanation {
            margin-bottom: 30px;
        }

        @include breakpoint(black-bear) {
            text-align: center;
        }
    }

    &__text {
        font-size: 15px;
        line-height: 20px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    &__form {
        margin-top: 16px;
        padding: 0 40px;

        @include breakpoint(black-bear) {
            padding: 0 31px;
        }
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 94px;
    }

    &__field {
        margin-bottom: 20px;
    }

    &__field :deep(.uikit-textarea__label) {
        color: $brand-black;
        margin-left: 0;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;

        @include breakpoint(black-bear) {
            padding-right: 0;
        }

        &.uikit-textarea__label--dark {
            color: $white;
        }
    }

    &__field-input :deep(.uikit-textarea__textarea) {
        margin-left: -9px;
        margin-right: -9px;
        width: calc(100% + 18px) !important;
        height: 108px;

        @include breakpoint(black-bear) {
            height: 72px;
            margin: 0;
        }
    }

    &__actions {
        margin-top: 4px;
        align-self: center;
        padding: 0 40px 32px;
    }

    &__submit-btn {
        margin-left: 8px;
    }
}
</style>