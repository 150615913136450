import { runCloudFunction } from '@/store/parseUtils'
import { subscriptionModule } from '@/store/subscription/module'
import type { Study } from '@pocketprep/types'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { analyticsModule } from '@/store/analytics/module'

const validateLicense = async (licenseKey: string) => {
    return runCloudFunction<Study.Cloud.validateLicense>('validateLicense', {
        licenseKey,
    })
}

const activateLicense = async (license: Study.Class.LicenseJSON) => {
    // Note that all linkedLicenses will be activated here as well, but we will update the current exam with the
    // passed in licenseKey
    await runCloudFunction<Study.Cloud.activateLicenseV2>('activateLicense-v2', {
        licenseKey: license.licenseKey,
        platform: 'Web',
    })

    await userExamMetadataModule.actions.fetchUserExamMetadata(true)

    const examGuid = license.examGuid
    if (!examGuid) {
        return
    }

    const uemForExamGuid = userExamMetadataModule.getters.getUserExamMetadataForExamGuid(examGuid)
    const examMetadata = uemForExamGuid?.examMetadata
        || examMetadataModule.getters.getMostRecentExamForExamGuid(examGuid)
    
    await userModule.actions.updateCurrentExam(examMetadata.objectId)
    analyticsModule.actions.updateIntercom()
}

const fetchUsersLicenseOrgName = async () => {
    const user = userModule.state.user
    const hasSubscriptions = !!subscriptionModule.state.subscriptions.filter(s => 'examGuid' in s).length
    return hasSubscriptions && user && (!user.firstName || !user.lastName)
        ? runCloudFunction<Study.Cloud.fetchLicenseOrgName>('fetchLicenseOrgName')
        : undefined
}

const fetchOrgNameFromLicenseCode = async (licenseCode: string) => {
    return runCloudFunction<Study.Cloud.fetchOrgNameFromLicenseCode>('fetchOrgNameFromLicenseCode', { licenseCode })
}

export default {
    validateLicense,
    activateLicense,
    fetchUsersLicenseOrgName,
    fetchOrgNameFromLicenseCode,
}
