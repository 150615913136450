<template>
    <div>
        <AddPaymentMethodSidePanel 
            v-if="showAddPaymentMethodSidePanel"
            key="editSubscriptionSidePanel"
            :disable-transition="true"
            :show-full-height="false"
            @close="showAddPaymentMethodSidePanel = false; disableTransition = true"
        />
        <Portal v-if="!showAddPaymentMethodSidePanel" to="sidepanel">
            <SidePanel
                key="editSubscriptionSidePanel"
                class="edit-subscription"
                name="Edit Subscription"
                :disable-transition="disableTransition"
                width="medium"
                :is-dark-mode="isDarkMode"
                @close="emitClose"
            >
                <div v-dark class="edit-subscription__label">
                    Subscription
                </div>
                <div v-dark class="edit-subscription__card">
                    <BundleIcon
                        v-if="bundle"
                        :bundle-id="bundle.objectId"
                        class="edit-subscription__card-bundle-icon"
                    />
                    <div v-dark class="edit-subscription__card-title">
                        {{ bundle && bundle.name }} Premium
                    </div>
                    <div v-dark class="edit-subscription__card-info">
                        Premium access to {{ (bundleUniqueExams && bundleUniqueExams.length) || 0 }}
                        {{ (bundleUniqueExams && bundleUniqueExams.length > 1) ? 'exams' : 'exam' }}, full question
                        banks, all quiz modes, and the
                        <PocketLink
                            href="https://help.pocketprep.com/en/articles/3697209-what-is-the-pass-guarantee" 
                            target="_blank"
                            :is-dark-mode="isDarkMode"
                        >
                            pass guarantee.
                        </PocketLink>
                    </div>
                    <div 
                        v-if="hasExpired || (subscription && !subscription.willAutoRenew)" 
                        v-dark
                        class="edit-subscription__card-expired"
                    >
                        {{ hasExpired ? 'Expired' : 'Expires' }} {{ expirationDate }}
                    </div>
                    <div
                        v-else-if="subscription"
                        v-dark
                        class="edit-subscription__card-renews"
                    >
                        <template v-if="stripeSubscription && stripeSubCost">
                            {{ stripeSubCost }} 
                            <span>•</span>
                        </template>
                        Renews {{ expirationDate }}
                    </div>
                    <template v-if="receipt && (receipt.source === 'Apple' || receipt.source === 'Google')">
                        <PocketLink
                            href="https://help.pocketprep.com/en/articles/3697799-how-does-the-subscription-work"
                            target="_blank"
                            class="edit-subscription__card-link"
                            :is-dark-mode="isDarkMode"
                        >
                            Manage Subscription on the {{ receipt.source === 'Apple' ? 'App' : 'Play' }} Store
                            <Icon type="externalLink" />
                        </PocketLink>
                        <div class="edit-subscription__card-helper">
                            Your subscription is managed through your
                            {{ receipt.source === 'Apple' ? 'Apple App' : 'Google Play' }} Store account. Questions on 
                            editing your subscription? Our <PocketLink
                                href="https://help.pocketprep.com/en/articles/3697799-how-does-the-subscription-work" 
                                target="_blank"
                                :is-dark-mode="isDarkMode"
                            >
                                help center
                            </PocketLink> has answers.
                        </div>
                    </template>
                </div>
                <template v-if="receipt.source === 'Stripe' || hasExpired">
                    <PocketSelect
                        v-model="plan"
                        label="Plan"
                        class="edit-subscription__plan"
                        :data="planOptions"
                        :is-dark-mode="isDarkMode"
                    />
                    <PocketSelect
                        v-if="plan && plan.value !== 'basic'"
                        v-model="paymentMethod"
                        label="Payment Method"
                        class="edit-subscription__payment-method"
                        :data="paymentMethodOptions"
                        :is-dark-mode="isDarkMode"
                        @linkClick="addPaymentMethod"
                    />
                    <Errors
                        v-if="pmErrors.length"
                        class="edit-subscription__pm-errors"
                        :errors="pmErrors"
                        :is-dark-mode="isDarkMode"
                    />
                </template>
                <div v-dark class="edit-subscription__changes">
                    Changes made to your subscription will take effect immediately. 
                    If you choose to update your subscription to a longer billing cycle, 
                    changes in cost will be prorated.
                    If you cancel your subscription,
                    you will still have access to premium features until your expiration date.
                </div>
                <template #details>
                    <PocketButton
                        v-if="willAutoRenew"
                        :disabled="isLoading"
                        type="secondary"
                        class="edit-subscription__cancel"
                        :is-dark-mode="isDarkMode"
                        @click="cancelSubscription"
                    >
                        Cancel Subscription
                    </PocketButton>
                </template>
                <template #action>
                    <PocketButton
                        :is-dark-mode="isDarkMode"
                        :disabled="!areSettingsChanged || (!!plan && plan.value !== 'basic' && !paymentMethod)"
                        :is-loading="isLoading"
                        @click="saveChanges"
                    >
                        Save Changes
                    </PocketButton>
                </template>
            </SidePanel>
        </Portal>
    </div>
</template>

<script lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { stripeModule } from '@/store/stripe/module'
import { subscriptionModule } from '@/store/subscription/module'
import { userModule } from '@/store/user/module'
import { months } from '@/utils'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import AddPaymentMethodSidePanel from '@/components/Settings/AddPaymentMethodSidePanel.vue'
import { toastModule } from '@/store/toast/module'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketLink: UIKit.Link,
        BundleIcon: UIKit.BundleIcon,
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        PocketSelect: UIKit.Select,
        Errors: UIKit.Errors,
        AddPaymentMethodSidePanel,
    },
})
export default class EditSubscriptionSidePanel extends Vue {
    @Prop() subscription!: ReturnType<Study.Class.SubscriptionBundle['toJSON']>

    isLoading = true
    plan: null | { label: string; value: string } = null
    originalPlan: null | { label: string; value: string } = null
    paymentMethod: null | { label: string; value: string } = null
    originalPaymentMethod: null | { label: string; value: string } = null
    showAddPaymentMethodSidePanel = false
    disableTransition = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get bundle () {
        return this.subscription 
            && bundleModule.getters.getBundles().find(b => b.objectId === this.subscription.bundle.objectId)
    }

    get receipt () {
        return this.subscription?.receipt as Study.Class.ReceiptJSON
    }

    get hasExpired () {
        return new Date(this.subscription?.expiresAt.iso) < new Date()
    }

    get expirationDate () {
        const date = new Date(this.subscription?.expiresAt.iso)
        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    }

    get willAutoRenew () {
        return this.subscription.willAutoRenew
    }

    get stripeSubscription () {
        const stripeSubscriptions = stripeModule.getters.getStripeSubscriptions()
        return stripeSubscriptions.find(s => s.id === this.subscription?.guid)
    }

    get stripeSubCost () {
        const plan = this.stripeSubscription?.items.data[0]?.plan
        return plan && stripeModule.getters.getPlanLabel({ p: plan, isLongLabel: true })
    }

    get planOptions () {
        return [
            { value: 'basic', label: 'Free Prep' },
            ...(this.bundle && stripeModule.getters.getStripePlanOptions(this.bundle.objectId)) || [],
        ]
    }

    get paymentMethodOptions () {
        return [
            ...stripeModule.getters.getStripePaymentMethods().map(pm => ({
                value: pm.id,
                label: `Card ending in ${pm.card?.last4} (Exp ${pm.card?.exp_month}/${
                    String(pm.card?.exp_year || 0).substr(2, 3)})`,
            })),
            { value: 'add', label: '+ Add Payment Method', type: 'link' },
        ]
    }

    get pmErrors () {
        const pm = stripeModule.getters.getStripePaymentMethods().find(spm => spm.id === this.paymentMethod?.value)
        const expiration = pm && new Date(`${pm?.card?.exp_month || 0 + 1}/01/${pm?.card?.exp_year}`)
        if (expiration && new Date().getTime() > expiration.getTime()) {
            return [ 'Card expired.' ]
        }

        return []
    }

    get areSettingsChanged () {
        if (this.plan?.value !== this.originalPlan?.value 
            || this.paymentMethod?.value !== this.originalPaymentMethod?.value
        ) {
            return true
        }

        return false
    }

    get bundleUniqueExams () {
        return this.bundle && bundleModule.getters.getBundleUniqueExams(this.bundle.objectId)
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            bundleModule.actions.fetchBundles(),
            stripeModule.actions.fetchStripeSubscriptions(),
            stripeModule.actions.fetchStripePlans(),
            stripeModule.actions.fetchPaymentMethods(),
        ])
        this.plan = this.willAutoRenew
            ? this.planOptions?.find(p => p.value === this.stripeSubscription?.items.data[0]?.plan.id) || null
            : { value: 'basic', label: 'Free Prep' }
        this.originalPlan = this.plan
        this.paymentMethod = this.paymentMethodOptions.find(
            pm => pm.value === this.stripeSubscription?.default_payment_method
        ) || null
        this.originalPaymentMethod = this.paymentMethod

        this.isLoading = false
    }

    addPaymentMethod () {
        this.showAddPaymentMethodSidePanel = true
    }

    async cancelSubscription () {
        this.plan = { value: 'basic', label: 'Free Prep' }
        this.saveChanges()
    }

    async saveChanges () {
        if (!this.plan || (this.plan.value !== 'basic' && !this.paymentMethod)) {
            throw new Error('No Payment Method or Plan selected.')
        }

        if (this.isLoading) {
            return
        }

        this.isLoading = true

        if (this.receipt.source === 'Stripe') {
            await stripeModule.actions.updateStripeSubscription({
                paymentMethodId: this.paymentMethod?.value,
                subscriptionId: this.subscription.objectId,
                planId: this.plan.value,
                planChanged: this.plan?.value !== this.originalPlan?.value,
            })
        } else if (this.paymentMethod) {
            await subscriptionModule.actions.subscribeUser({
                paymentMethodId: this.paymentMethod.value,
                plan: this.plan.value,
            })
        }
        this.originalPlan = this.plan
        this.originalPaymentMethod = this.paymentMethod

        this.isLoading = false
        if (this.plan.value === 'basic') {
            toastModule.actions.displayToast({
                title: 'Your Premium subscription was cancelled',
            })
        } else {
            const cadence = this.plan.label.split(' - ')[0]
            toastModule.actions.displayToast({
                title: `Premium ${cadence} subscription activated!`,
            })
        }
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.edit-subscription {
    &__label {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 6px;
        color: $slate-01;

        &--dark {
            color: $fog;
        }
    }

    &__card {
        position: relative;
        background: $white;
        border-radius: 5px;
        box-shadow: 0 1px 6px 0 rgba($gray-divider, 0.6);
        padding: 12px;
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 28px;

        &--dark {
            background: $moonlit-ocean;
            box-shadow: 0 1px 6px 0 rgba($charcoal, 0.3);
        }
    }

    &__card-bundle-icon {
        width: 52px;
        height: 52px;
        position: absolute;
        top: 1px;
        right: 3.5px;
    }

    &__card-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 4px;
        max-width: 310px;
    }

    &__card-info {
        font-size: 14px;
        line-height: 18px;
        color: $slate-03;
        max-width: 310px;
        margin-bottom: 8px;

        &--dark {
            color: $fog;
        }

        a {
            font-weight: 500;
        }
    }

    &__card-expired {
        font-size: 13px;
        line-height: 18px;
        color: $red-pegasus;

        &--dark {
            color: $rosa;
        }
    }

    &__card-renews {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $fog;
        }

        span {
            margin: 0 5px;
        }
    }

    &__card-link {
        margin-top: 13px;
        margin-bottom: 12px;
        display: block;

        svg {
            margin-left: 5px;
        }
    }

    &__card-helper {
        font-size: 13px;
        line-height: 18px;
        color: $slate-01;

        &--dark {
            color: $fog;
        }
    }

    &__plan {
        margin-bottom: 23px;
        margin-left: -12px;
        margin-right: -12px;
    }

    &__payment-method {
        margin-bottom: 14px;
        margin-left: -12px;
        margin-right: -12px;
    }

    &__pm-errors {
        margin-left: -12px;
        margin-right: -12px;
    }

    &__changes {
        font-size: 14px;
        line-height: 18px;
        color: $slate-03;
        margin-top: 32px;

        &--dark {
            color: $pewter;
        }
    }

    &__cancel {
        margin-left: -20px;
    }
}
</style>