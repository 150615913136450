import { objPointer, runCloudFunction } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { fetchLoadable } from '@/store/utils'
import { mockExamModule } from '@/store/mockExam/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import type { TActiveMockExamQuiz } from '@/store/mockExam/state'
import { quizModule } from '@/store/quiz/module'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { utils } from '@pocketprep/ui-kit'
import { analyticsModule } from '@/store/analytics/module'
import { localISODateString } from '@/utils'

const fetchMockExams = async () => {
    // If we don't have a user (or a user who isn't studying anything), don't try to fetch the mock exams yet
    const isStudying = userModule.state.user?.currentUserExamMetadata
    if (!isStudying) {
        return null
    }

    // We could fetch the mock exams without the exam metadata, but we'll always need it before reading the mock exams
    await examMetadataModule.actions.fetchExamMetadata()

    return fetchLoadable(mockExamModule.state.mockExams, async () => {
        return runCloudFunction<Study.Cloud.fetchMockExams>('fetchMockExams')
    })
}

const createActiveMockExamQuiz = async (params: {
    mockExamId: string
    getsExtraTime: boolean
}) => {
    await examMetadataModule.actions.fetchExamMetadata()
    await mockExamModule.actions.fetchMockExams()
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    if (!currentExam) {
        throw new Error('No current exam found')
    }

    await analyticsModule.actions.trackEvent('Mock_Exam_Started')

    const examGuid = currentExam.examGuid
    const { mockExamId, getsExtraTime } = params

    const currentMockExam = mockExamModule.getters.getMockExams().find(mockExam => mockExam.objectId === mockExamId)
    if (!currentMockExam) {
        throw new Error('No mock exam found')
    }

    const mockExamQuestions = 
    await runCloudFunction<Study.Cloud.fetchMockExamQuestionsV3>('fetchMockExamQuestions-v3', {
        mockExamId: params.mockExamId,
        includeMatrixQuestions: true,
    })

    // generate random answer seed for each question
    const answerSeeds = new Array(mockExamQuestions.length).fill(undefined).map(() => {
        return [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ].sort(() => Math.random() - 0.5)
    })

    const activeMockExamQuiz: TActiveMockExamQuiz = {
        questions: mockExamQuestions,
        answers: [],
        examGuid,
        mockExam: objPointer(mockExamId, 'MockExam'),
        mode: 'mockExam',
        getsExtraTime,
        startedAt: localISODateString(),
        timeLimit: Math.round(currentMockExam.durationSeconds / 60), // convert to minutes
        currentQuestionIndex: 0,
        durationSeconds: 0,
        breakDurationSeconds: 0,
        timerType: 'exam',
        answerSeeds,
    }
    updateActiveMockExamQuiz(activeMockExamQuiz)
}

const updateActiveMockExamQuiz = (activeMockExamQuiz: TActiveMockExamQuiz | null) => {
    if (activeMockExamQuiz) {
        // Reset activeQuiz when setting activeMockExamQuiz
        quizModule.actions.updateActiveQuiz(null)
    }
    mockExamModule.state.activeMockExamQuiz = activeMockExamQuiz
    localStorage.setItem('activeMockExamQuiz', JSON.stringify(activeMockExamQuiz))
}

const recordMockExamQuiz = async (params: Parameters<Study.Cloud.recordQuiz>[0]) => {
    // Remove checked property from answers (only needed for active quiz state)
    params.answers.forEach(ans => {
        const ansWithChecked = ans as typeof ans & { checked?: boolean }
        delete ansWithChecked.checked
    })
    const recordMockExamQuizResponse = await runCloudFunction<Study.Cloud.recordQuiz>('recordMockExam', params)

    // update quiz and global metrics in store
    quizModule.state.quizzes.push(recordMockExamQuizResponse.quiz)
    mockExamModule.actions.updateActiveMockExamQuiz(null)
    await globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(true)

    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    if (currentExam) {
        await analyticsModule.actions.googleAnalyticsTrack('Quiz Submission', {
            'event_category': utils.studyModes[params.mode as -1 | 0 | 2 | 3 | 4 | 5 | 6 | 10].name,
            'event_label': currentExam.nativeAppName,
        })
    }

    analyticsModule.actions.updateIntercom()

    return recordMockExamQuizResponse
}

export default {
    fetchMockExams,
    createActiveMockExamQuiz,
    updateActiveMockExamQuiz,
    recordMockExamQuiz,
}
