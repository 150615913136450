<template>
    <div class="study-activity-card" v-dark>
        <div class="study-activity-card__activity-left" aria-hidden="true">
            <div class="study-activity-card__activity-icon">
                <Icon :type="activity.icon" :style="{ color: activity.iconColor }" />
            </div>
            <div v-dark class="study-activity-card__activity-name">
                {{ activity.name }}
            </div>
            <div 
                v-if="levelUpQuizLevel && numOfLevelsInSubject"
                v-dark
                class="study-activity-card__level-up-info"
                :aria-label="`Level ${levelUpQuizLevel} of ${numOfLevelsInSubject}`"
            >
                Level {{ levelUpQuizLevel }}/{{ numOfLevelsInSubject }}
            </div>
        </div>
        <div
            v-dark
            class="study-activity-card__activity-date"
            aria-hidden="true"
        >
            {{ activity.date }}
        </div>
        <div class="study-activity-card__activity-score-container" aria-hidden="true">
            <div
                v-dark
                class="study-activity-card__activity-score"
                :class="{
                    [`study-activity-card__activity-score--${activity.scoreRange}`]: true,
                    [`study-activity-card__activity-score--${activity.score}`]: 
                        activity.name === 'Question of the Day'
                }"
            >
                <template v-if="activity.name === 'Question of the Day'">
                    <template v-if="activity.isLocked">
                        <Icon
                            v-dark
                            type="lock"
                            class="study-activity-card__lock"
                            title="Locked"
                        />
                    </template>
                    <Icon
                        v-if="activity.score === 'correct'"
                        v-dark
                        type="correct"
                        class="study-activity-card__correct"
                        title="Correct"
                    />
                    <Icon
                        v-else
                        v-dark
                        type="incorrect"
                        class="study-activity-card__incorrect"
                        title="Incorrect"
                    />
                </template>
                <template v-else>
                    {{ activity.score }}
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { screenModule } from '@/store/screen/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { quizModule } from '@/store/quiz/module'

type TStudyActivity = { 
    quizId: string
    isLocked: boolean
    icon: string
    name: string
    iconColor: string
    date: string
    score: string
    scoreRange: string
}

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class StudyActivityCard extends Vue {
    @Prop() activity!: TStudyActivity

    subjectLevels: number | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get currentQuiz () {
        return quizModule.getters.getQuizById(this.activity.quizId)
    }

    get levelUpQuizLevel () {
        return this.currentQuiz?.levelUpProgress?.level
    }
    get levelUpQuizSubject () {
        return this.currentQuiz?.levelUpProgress?.subjectName
    }

    get numOfLevelsInSubject () {
        return examMetadataModule.getters.getLevelsInSubject(this.levelUpQuizSubject)?.length
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            quizModule.actions.fetchAnsweredQuestions(),
            examMetadataModule.actions.fetchExamMetadata(),  
            quizModule.actions.fetchPrebuiltQuizzes(),
        ])

        if (this.currentQuiz?.mode === 6 && this.currentExam) {
            await examMetadataModule.actions.fetchSubjectsWithLevels({
                examGuid: this.currentExam.examGuid,
                examMetadataId: this.currentExam.objectId,
                majorVersion: this.currentExam.version.split('.')[0],
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.study-activity-card {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 41px;
    max-height: 41px;
    position: relative;
    align-items: center;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    padding: 0 9px;

    &::before {
        content: '';
        position: absolute;
        background: rgba($gray-divider, 0.5);
        left: 39px;
        bottom: -1px;
        height: 1px;
        width: calc(100% - 39px);
        z-index: 1;
    }

    &:focus {
        &::after {
            content: '';
            position: absolute;
            left: -10px;
            top: 1px;
            width: calc(100% + 20px);
            height: calc(100% - 3px);
            border: 1px solid $brand-blue;
            border-radius: 6px;
        }
    }

    &:hover {
        background-color: $gray-background;
    }

    &--dark {
        &::before {
            background-color: rgba($pewter, 0.6);
        }

        &:focus::after {
            border-color: $banana-bread;
        }

        &:hover {
            background-color: $brand-black;
        }
    }

    &__activity-left {
        display: flex;
        align-items: center;
        width: 240px;
    }

    &__activity-icon {
        width: 25px;
        height: 24px;
        margin-right: 12px;

        svg {
            width: 100%;
        }
    }

    &__activity-name {
        font-size: 14px;
        line-height: 19px;
        color: $brand-black;

        &--dark {
            color: white
        }
    }

    &__level-up-info {
        color: $ash;
        font-size: 14px;
        line-height: 19px;
        margin-left: 8px;

        &--dark {
            color: $fog;
        }
    }

    &__activity-date {
        color: $slate;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        width: 70px;

        &--dark {
            color: rgba(255, 255, 255, 0.70);
        }
    }

    &__activity-score-container {
        width: 86px;
        display: flex;
        justify-content: flex-end;
    }

    &__activity-score {
        border-radius: 3px;
        height: 24px;
        padding: 0 5px;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 14px;

        &--dark {
            color: $white;
        }

        &--low {
            background: $red-hint;

            &--dark {
                background: rgba($brand-red, 0.3);
            }
        }

        &--medium {
            background: $cream-puff;

            &--dark {
                background: rgba($butterscotch, 0.18);
            }
        }

        &--high {
            background: $barely-green;

            &--dark {
                background: rgba($jungle-green, 0.2);
            }
        }

        &--correct,
        &--incorrect {
            background: transparent !important;
            display: flex;
            justify-content: flex-end;
        }

        &--correct {
            margin-right: -4px;
            color: $spectral-green;
            width: 48px;

            &--dark {
                color: $jungle-green;
            }
        }

        &--incorrect {
            margin-right: -4px;
            width: 48px;
            color: $red-pegasus;

            &--dark {
                color: $rosa;
            }
        }
    }

    &__lock {
        width: 22px;
        height: 22px;
        color: $slate;

        &--dark {
            color: $pewter;
        }
    }

    &__correct {
        width: 22px;
        height: 22px;
        color: $cadaverous;

        &--dark {
            color: $jungle-green;
        }
    }

    &__incorrect {
        height: 22px;
        width: 22px;
        color: $pepper;

        &--dark {
            color: $rosa;
        }
    }
}
</style>