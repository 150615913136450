import { runCloudFunction } from '@/store/parseUtils'
import { fetchLoadable } from '@/store/utils'
import { questionModule } from '@/store/question/module'
import type { Study } from '@pocketprep/types'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'

const fetchSerialQuestionInfoLib = async (params?: { examGuid: string; version: string }) => {
    await Promise.all([
        userExamMetadataModule.actions.fetchUserExamMetadata(),
        examMetadataModule.actions.fetchExamMetadata(),
    ])

    const currentExamMetadata = examMetadataModule.getters.getCurrentExamMetadata()
    if (!currentExamMetadata) {
        return
    }

    const compositeKey = params 
        ? `${params.examGuid.toLowerCase()}/${params.version}` 
        : currentExamMetadata.compositeKey
    const examMetadataId = currentExamMetadata.objectId

    return fetchLoadable(questionModule.state.serialQuestionInfoLib, () =>
        runCloudFunction<Study.Cloud.fetchSerialQuestionInfoLibV2>(
            'fetchSerialQuestionInfoLib-v2', 
            { compositeKey, examMetadataId }
        )
    )
}

export default {
    fetchSerialQuestionInfoLib,
}
