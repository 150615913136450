<template>
    <div v-dark class="review">
        <div 
            id="review"
            v-dark 
            class="review__head"
        >
            <Blob class="review__head-blob" />
            <h1
                v-dark
                class="review__title"
                role="alert"
            >
                Review Questions
            </h1>
            <div class="review__tabs" role="tablist">
                <ReviewTab
                    v-for="tab in tabs"
                    :key="tab"
                    :is-active="activeTab === tab"
                    :label="tab"
                    :count="questions[tab].length"
                    role="tab"
                    :aria-selected="activeTab === tab ? 'true' : 'false'"
                    @keydown.enter="activeTab = tab"
                    @click="activeTab = tab"
                />
            </div>
        </div>
        <div v-dark class="review__body">
            <div class="review__search">
                <div class="review__search-field">
                    <Search
                        v-model="searchString"
                        placeholder="Search Answered Questions"
                        type="white"
                        :is-dark-mode="isDarkMode"
                    />
                </div>
                <FilterDropdown
                    v-model="subjectFilter"
                    class="review__filter"
                    :align="breakpoint === 'black-bear' ? 'right' : 'left'"
                    size="large"
                    :responsive-button="true"
                    filterLabel="Filter by Subject"
                    :radioEntries="[]"
                    :checkboxEntries="subjectFilter"
                    :is-dark-mode="isDarkMode"
                />
            </div>
            <div v-if="enabledFilterSubjects.length" class="review__filters">
                <div v-dark class="review__filters-label">
                    Filters:
                </div>
                <Pill
                    v-for="filter in enabledFilterSubjects"
                    :key="filter"
                    class="review__filters-pill"
                    :show-close="true"
                    @click="removeFilter(filter)"
                >
                    {{ filter }}
                </Pill>
            </div>
            <div v-if="questions[activeTab].length" class="review__questions">
                <QuestionCard
                    v-for="question in questions[activeTab]"
                    :key="question.serial"
                    class="review__question"
                    :question="question"
                    @click="openReviewModal(question.serial)"
                    @keypress.enter="openReviewModal(question.serial)"
                />
            </div>
            <div 
                v-if="
                    !questions[activeTab].length &&
                        (searchString || enabledFilterSubjects.length)
                "
                class="review__blank"
            >
                <EmptyState
                    v-if="enabledFilterSubjects.length"
                    class="review__empty-state"
                    title="No results"
                    message="There are no answered questions that match your filter and search criteria."
                    :is-dark-mode="isDarkMode"
                >
                    <template #imageRow>
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/review/brain-anxious.png"
                            alt=""
                            class="review__blank-anxious-image"
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/review/brain-anxious-dm.png"
                            alt=""
                            class="review__blank-anxious-image"
                        >
                    </template>
                </EmptyState>
                <EmptyState
                    v-else-if="searchString"
                    class="review__empty-state"
                    :title="noSearchStringResults"
                    message="Only question text is searchable. Check your spelling or try again!"
                    :is-dark-mode="isDarkMode"
                >
                    <template #imageRow>
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/review/brain-anxious.png"
                            alt=""
                            class="review__blank-anxious-image"
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/review/brain-anxious-dm.png"
                            alt=""
                            class="review__blank-anxious-image"
                        >
                    </template>
                </EmptyState>
            </div>
            <div
                v-if="!questions[activeTab].length && 
                    !searchString && 
                    !enabledFilterSubjects.length
                " 
                class="review__blank"
            >
                <EmptyState
                    v-if="activeTab === 'All'"
                    class="review__empty-state"
                    title="Start studying"
                    message="Then come back here to review all of your answered questions."
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                >
                    <template #imageRow>
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/review/brain-pencil.png"
                            alt=""
                            class="review__blank-pencil-image"
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/review/brain-pencil-dm.png"
                            alt=""
                            class="review__blank-pencil-image"
                        >
                    </template>
                </EmptyState>
                <EmptyState
                    v-else-if="activeTab === 'Flagged'"
                    class="review__empty-state"
                    state="flagged"
                    :title="breakpoint !== 'black-bear' ? 'You haven’t flagged any questions.' : '0 Flagged Questions'"
                    :message="breakpoint !== 'black-bear' ? 
                        'Click the flag icon at the bottom of any question and you can review them here.' : 
                        'Tap the flag icon at the bottom of any question and you can review them here.'
                    "
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                />
                <EmptyState
                    v-if="activeTab === 'Incorrect'"
                    class="review__empty-state"
                    state="incorrect"
                    :title="breakpoint !== 'black-bear' ? 
                        'You don’t have any incorrect questions.' : '0 Incorrect Questions'"
                    message="Impressive!"
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                />
                <EmptyState
                    class="review__empty-state"
                    v-else-if="activeTab === 'Correct'"
                    state="correct"
                    :title="breakpoint !== 'black-bear' ? 
                        'You don’t have any correct questions.' : '0 Correct Questions'"
                    backgroundColor="white"
                    :is-dark-mode="isDarkMode"
                />
                <PocketButton
                    v-if="activeTab === 'All'"
                    class="review__blank-button"
                    :is-dark-mode="isDarkMode"
                    :disabled="isLoading"
                    @click="startQuick10"
                >
                    Take a Quick 10
                </PocketButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-facing-decorator'
import Blob from '@/assets/review/PocketBlob.vue'
import NoResultsBlob from '@/assets/review/NoResultsBlob.vue'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { quizModule } from '@/store/quiz/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { questionModule } from '@/store/question/module'
import { subscriptionModule } from '@/store/subscription/module'
import ReviewTab from '@/components/ReviewTab.vue'
import QuestionCard from '@/components/QuestionCard.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { screenModule } from '@/store/screen/module'
import { qotdModule } from '@/store/qotd/module'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import type { Study } from '@pocketprep/types'
import { type TFilterOption, stripHtmlTags } from '@/utils'

type TQuestionRow = {
    serial: string
    subjectName: string
    prompt: string
    isFlagged: boolean
    isCorrect: boolean
    isLocked: boolean
    scenarioPartLabel: string
}
type TQuestionsLib = {
    [key: string]: TQuestionRow[]
}

@Component({
    components: {
        Blob,
        NoResultsBlob,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Search: UIKit.Search,
        ReviewTab,
        QuestionCard,
        Pill: UIKit.Pill,
        EmptyState: UIKit.EmptyState,
        FilterDropdown: UIKit.FilterDropdown,
    },
})
export default class Review extends Vue {
    activeTab = 'All'
    searchString = ''
    tabs = [
        'All',
        'Flagged',
        'Incorrect',
        'Correct',
    ]
    subjectFilter: TFilterOption[] = []
    brandColors = BrandColors
    isLoading = false

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get flaggedQuestionSerials () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()?.flaggedQuestions || []
    }

    get hasSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get enabledFilterSubjects () {
        return this.subjectFilter.reduce<string[]>((acc, s) => {
            if (s.enabled) {
                acc.push(s.label)
            }
            return acc
        }, [])
    }

    get noSearchStringResults () {
        return `No results for "${this.searchString}"`
    }

    get emptyStateFlaggedMessage () {
        return `No results for ${this.searchString}`
    }

    get todaysQotD () {
        return qotdModule.getters.getQotDQuestion()
    }

    get answeredQuestionsSerialLib () {
        return quizModule.getters.getAnsweredQuestions().reduce((acc, q) => {
            if (!acc[q.serial]) {
                acc[q.serial] = q
            }
            return acc
        }, {} as { [serial: string]: Study.Class.QuestionJSON })
    }

    get questions () {
        // regex escape from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
        const promptRegex = new RegExp(this.searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')
        const sortedBestAnswers = Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
            .sort((a, b) => b.answerDate - a.answerDate)
        return sortedBestAnswers.reduce<TQuestionsLib>((acc, answer) => {
            const serial = answer.questionSerial
            const question = this.answeredQuestionsSerialLib[serial]
            if (!question) {
                return acc
            }

            const isFlagged = !!this.flaggedQuestionSerials.includes(serial)
            const isCorrect = answer.isCorrect
            const subject = question.subject as Study.Class.SubjectJSON
            const subjectName = subject.name
            const isLocked = !this.hasSubscription
                && !question.isFree
                && this.todaysQotD?.serial !== serial
            let prompt = stripHtmlTags(question.prompt)
            const scenarioPartLabel = this.scenarioPartLabel(question)

            if (this.searchString && !prompt.toLowerCase().includes(this.searchString.toLowerCase())) {
                return acc
            } else if (this.searchString) {
                prompt = prompt.replace(promptRegex, '<span>$&</span>')
            }

            if (this.enabledFilterSubjects.length && !this.enabledFilterSubjects.includes(subjectName)) {
                return acc
            }

            const questionRow = {
                serial,
                prompt,
                isFlagged,
                isCorrect,
                isLocked,
                subjectName,
                scenarioPartLabel,
            }

            if (isFlagged) {
                acc.Flagged?.push(questionRow)
            }
            if (isCorrect) {
                acc.Correct?.push(questionRow)
            }
            if (!isCorrect) {
                acc.Incorrect?.push(questionRow)
            }

            acc.All?.push(questionRow)
            return acc
        }, { All: [], Flagged: [], Correct: [], Incorrect: [] })
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            this.fetchAnsweredQuestions(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
        ])

        const reviewEl = document.getElementById('review')
        if (reviewEl && this.breakpoint !== 'black-bear') {
            reviewEl.scrollIntoView()
        }
        if (this.$route.query.subject) {
            this.subjectFilter = this.subjectFilter.map(s => {
                if (this.$route.query.subject === s.label || this.$route.query.subject?.includes(s.label)) {
                    s.enabled = true
                }

                return s
            })
        }

        if (typeof this.$route.query.type === 'string') {
            this.activeTab = this.$route.query.type
        }

        if (Object.keys(this.$route.query).length) {
            this.$router.replace({
                name: 'review',
            })
        }
    }

    async fetchAnsweredQuestions () {
        if (!userModule.state.user) {
            return
        }

        await Promise.all([
            quizModule.actions.fetchAnsweredQuestions(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
        ])
        this.setSubjectFilterOptions()
    }

    @Watch('answeredQuestionsSerialLib', { deep: true })
    answeredQuestionsSerialLibChanged () {
        this.setSubjectFilterOptions()
    }

    removeFilter (filterId: string) {
        this.subjectFilter = this.subjectFilter.map(o => o.id === filterId ? { ...o, enabled: false } : o)
    }

    setSubjectFilterOptions () {
        const examMetadata = examMetadataModule.getters.getCurrentExamMetadata()
        this.subjectFilter = Object.values(examMetadata?.knowledgeAreas || {}).map(subject => ({
            id: subject.name,
            label: subject.name,
            enabled: false,
        }))
    }

    openReviewModal (questionSerial: string) {
        this.$router.push({
            name: 'question-review',
            query: {
                questionSerial,
                subject: this.enabledFilterSubjects,
                type: this.activeTab,
            },
        })
    }

    scenarioPartLabel (question: Study.Class.QuestionJSON) {
        return questionModule.getters.getQuestionScenarioPartLabel(question)
    }

    async startQuick10 () {
        this.isLoading = true
        await quizModule.actions.createActiveQuiz({ mode: 'quick10', questionCount: 10 })

        this.$router.push({ name: 'quiz' })
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>
@mixin container-styles {
    max-width: 852px;
    width: 100%;
    padding: 0 36px;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        padding: 0 18px;
    }
}

.review {
    background: $gray-background;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 54px);

    &--dark {
        background: transparent;
        color: $white;
    }

    &__head {
        position: relative;
        padding: 55px 0 0;
        background: $white;

        &--dark {
            background: $brand-black;
        }

        @include breakpoint(black-bear) {
            padding-top: 35px;
        }
    }

    &__head-blob {
        position: absolute;
        right: 0;
        bottom: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__title {
        @include container-styles;

        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        margin: 0 auto 36px;
    }

    &__tabs {
        @include container-styles;

        display: flex;
        margin: 0 auto;
    }

    &__filters {
        @include container-styles;

        margin: 14px auto -8px;
    }

    &__filters-label {
        font-size: 13px;
        line-height: 22px;
        color: $slate-03;
        margin-right: 9px;
        display: inline-block;
        margin-left: 12px;
        vertical-align: top;

        &--dark {
            color: rgba($white, 0.82);
        }
    }

    &__search {
        @include container-styles;

        display: flex;
        margin: 26px auto 0;
        align-items: center;
    }

    &__search-field {
        width: 359px;
        margin-right: 20px;

        @include breakpoint(black-bear) {
            margin-right: 14px;
        }
    }

    &__blank {
        text-align: center;
        padding-top: 66px;

        :deep(.uikit-empty-state) {
            margin: auto;
        }
    }

    &__blank-token {
        width: 40px;
        height: 40px;
        margin: 0 auto 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
    }

    &__blank-token-blob {
        position: absolute;
        top: 3.5px;
        left: 1px;
        width: 38px;
        height: 33px;
        z-index: -1;
    }

    &__blank-button {
        display: block;
        margin: 0 auto;
        margin-top: 22px;

        @include breakpoint(black-bear) {
            border-radius: 22px;
            width: 240px;
            margin-top: 40px;
        }
    }

    &__blank-pencil-image {
        width: 130px;
        display: block;
        margin: 0 auto 16px auto;
    }

    &__blank-anxious-image {
        width: 90px;
        display: block;
        margin: 0 auto 16px auto;
    }

    &__questions {
        @include container-styles;

        margin: 18px auto;
        padding-bottom: 55px;
    }
}
</style>