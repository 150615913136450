<template>
    <div>
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="quizModeSetupModal"
            :quiz-mode="mode"
            @close="showUpgradeSidePanel = false"
        />
        <Portal v-else to="modal">
            <ModalContainer
                key="quizModeSetupModal"
                :is-dark-mode="isDarkMode"
                @close="emitClose"
            >
                <template #modal>
                    <Modal
                        :show-close-button="true"
                        class="quiz-mode__modal"
                        :is-dark-mode="isDarkMode"
                        @close="emitClose"
                    >
                        <div class="quiz-mode">
                            <Icon
                                v-dark
                                :type="modeContent.icon"
                                class="quiz-mode__icon"
                            />
                            <div v-dark class="quiz-mode__quiz-mode">
                                {{ modeContent.name }}
                            </div>
                            <SubjectCard
                                v-if="mode === 'weakest'"
                                class="quiz-mode__subject"
                                :name="weakestSubject && weakestSubject.name"
                                :correct-count="weakestSubject && weakestSubject.correctCount"
                                :total-questions="weakestSubject && weakestSubject.totalCount"
                            />
                            <div
                                v-if="mode === 'missed'"
                                v-dark
                                class="quiz-mode__missed"
                            >
                                <div class="quiz-mode__missed-count">
                                    {{ missedQuestionCount }}
                                </div>
                                <div v-dark class="quiz-mode__missed-label">
                                    Incorrect
                                </div>
                            </div>
                            <template
                                v-if="
                                    mode === 'timed' 
                                        || (weakestSubject && mode ==='weakest') 
                                        || (missedQuestionCount && mode === 'missed')
                                "
                            >
                                <div class="quiz-mode__label">
                                    {{ modeContent.label }}
                                </div>
                                <PocketInput
                                    :modelValue="value"
                                    v-dark
                                    class="quiz-mode__input"
                                    :is-dark-mode="isDarkMode"
                                    :min="slider.min"
                                    :max="slider.max"
                                    @update:modelValue="inputChange"
                                />
                                <Slider
                                    v-if="value !== null"
                                    v-model="value"
                                    :min="slider.min"
                                    :max="slider.max"
                                    :step="slider.step"
                                    class="quiz-mode__slider"
                                    :is-dark-mode="isDarkMode"
                                />
                            </template>
                            <div v-if="!missedQuestionCount && mode === 'missed'" class="quiz-mode__no-missed">
                                <div class="quiz-mode__no-missed-title">
                                    You don’t have any missed questions.
                                </div>
                                <div class="quiz-mode__no-missed-subtitle">
                                    Impressive!
                                </div>
                            </div>
                            <div v-if="!weakestSubject && mode ==='weakest'" class="quiz-mode__no-missed">
                                <div class="quiz-mode__no-missed-title">
                                    No Weakest Subject
                                </div>
                                <div class="quiz-mode__no-missed-subtitle">
                                    Take more quizzes to determine your weakest subject.
                                </div>
                            </div>
                            <div class="quiz-mode__buttons">
                                <PocketButton
                                    :is-dark-mode="isDarkMode"
                                    type="secondary"
                                    @click="closeModal"
                                >
                                    Close
                                </PocketButton>
                                <PocketButton
                                    v-if="!hasActiveSubscription && modeContent.isPremium" 
                                    :is-dark-mode="isDarkMode"
                                    :disabled="bundle?.name === 'Finance'"
                                    :is-loading="isLoading"
                                    @click="startQuiz"
                                >
                                    Upgrade to Premium
                                </PocketButton>
                                <PocketButton
                                    v-if="hasActiveSubscription || !modeContent.isPremium" 
                                    :is-dark-mode="isDarkMode"
                                    :disabled="((!weakestSubject && mode ==='weakest') 
                                        || (!missedQuestionCount && mode === 'missed')
                                    ) || value === 0"
                                    :is-loading="isLoading"
                                    @click="startQuiz"
                                >
                                    Start Quiz
                                </PocketButton>
                            </div>
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import SubjectCard from '@/components/Study/SubjectCard.vue'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { subscriptionModule } from '@/store/subscription/module'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import type { TCreateActiveQuizParams } from '@/store/quiz/actions'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { allowedQuestionsPerMinute } from '@/utils'
import { bundleModule } from '@/store/bundle/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        Slider: UIKit.Slider,
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        SubjectCard,
        UpgradeSidePanel,
        PocketInput: UIKit.Input,
    },
})
export default class QuizModeSetupModal extends Vue {
    @Prop() mode!: 'timed' | 'weakest' | 'missed'

    isLoading = false
    showUpgradeSidePanel = false
    value: null | number = null
    slider = {
        min: 0,
        max: 100,
        step: 1,
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get modeContent () {
        if (this.mode === 'timed') {
            return {
                icon: 'stopwatch',
                name: 'Timed Quiz',
                label: 'How many minutes?',
                isPremium: false,
            }
        } else if (this.mode === 'weakest') {
            return {
                icon: 'subject',
                name: 'Weakest Subjects Quiz',
                label: 'How many questions?',
                isPremium: true,
            }
        } else {
            return {
                icon: 'missedQuestions',
                name: 'Missed Questions Quiz',
                label: 'How many questions?',
                isPremium: true,
            }
        }
    }

    get userDisabledSubjects () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()?.disabledSubjects || []
    }

    get missedQuestionCount () {
        const serialInfoLib = questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' })

        return Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
            .filter(a => {
                const qInfo = serialInfoLib[a.questionSerial]
                if (qInfo) {
                    return !a.isCorrect && !this.userDisabledSubjects.includes(qInfo.subject)
                }
                return
            })
            .length
    }

    get weakestSubject () {
        const sortedSubjectStats = quizModule.getters.getSortedSubjectStats()
        const userDisabledSubjects = this.userDisabledSubjects

        const weakestSubject = sortedSubjectStats.find(subjectInfo => !userDisabledSubjects.includes(subjectInfo.name))
        return weakestSubject || null
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return this.currentExamMetadata && bundleExam?.examGuid === this.currentExamMetadata.examGuid
            }))
    }

    async mounted () {
        this.isLoading = true
        await Promise.all([
            userModule.actions.fetchUserData(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            examMetadataModule.actions.fetchExamMetadata(),
            bundleModule.actions.fetchBundles(),
        ])

        if (this.mode === 'timed') {
            this.value = 5
        }

        if (this.mode === 'weakest') {
            const examMetadata = examMetadataModule.getters.getCurrentExamMetadata()
            const subjects = examMetadata?.knowledgeAreas
            const subject = subjects && Object.values(subjects).find(s => s.name === this.weakestSubject?.name)
            const subjectCount = subject && subject.count
            this.slider.max = subjectCount && subjectCount <= 100 ? subjectCount : 100
            this.slider.min = 0
            this.value = subjectCount && subjectCount ? 1 : 0
        }

        if (this.mode === 'missed') {
            this.slider.min = 0
            this.slider.max = Math.min(this.missedQuestionCount, 100)
            this.value = this.missedQuestionCount ? 1 : 0 
        }

        this.isLoading = false
    }

    inputChange (e: number) {
        this.value = Number(e)
    }

    async closeModal () {
        this.emitClose()
    }

    async startQuiz () {
        this.isLoading = true
        if (!Number(this.value) || this.value === 0) {
            this.value = 1
        }
        if (!this.hasActiveSubscription && this.mode !== 'timed') {
            this.showUpgradeSidePanel = true
        } else if (this.value) {
            const activeQuizParams: TCreateActiveQuizParams = {
                mode: this.mode,
                questionCount: this.mode === 'timed' ? (this.value * allowedQuestionsPerMinute) : this.value,
            }
            if (this.mode === 'timed') {
                activeQuizParams.timeLimit = this.value
            } else if (this.mode === 'weakest' && this.weakestSubject) {
                activeQuizParams.subjectNames = [ this.weakestSubject.name ]
            } else if (this.mode === 'missed') {
                activeQuizParams.include = {
                    incorrect: true,
                }
            }

            this.emitStart(activeQuizParams)
        }
        this.isLoading = false
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('start')
    emitStart (activeQuizParams: TCreateActiveQuizParams) {
        return activeQuizParams
    }
}
</script>
<style lang="scss" scoped>
.quiz-mode {
    width: 492px;
    text-align: center;
    padding: 34px 10px 32px;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        max-width: 326px;
        margin: 0 auto;
        width: 100%;
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__subject {
        margin: 40px auto;
    }

    &__icon {
        width: 22px;
        height: 24px;
        display: block;
        margin: 0 auto 8px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    &__quiz-mode {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 36px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    &__label {
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.43px;
        margin-bottom: 14px;
    }

    &__input {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: -2px;
        line-height: 50px;
        margin-bottom: 15px;

        :deep(.uikit-input__input) {
            width: 53px;
            text-align: center;
        }
    }

    &__slider {
        max-width: 335px;
        margin: 0 auto 48px;
    }

    &__no-missed {
        margin: -18px 0 40px;
    }

    &__no-missed-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    &__no-missed-subtitle {
        font-size: 14px;
        line-height: 17px;
        max-width: 300px;
        margin: 0 auto;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        .uikit-btn--primary,
        .uikit-btn--secondary {
            margin: 0 4px;
        }
    }

    &__missed {
        text-align: center;
        width: 262px;
        margin: 40px auto 38px;
        padding: 13px 0 8px;
        background: $red-hint;
        border-radius: 8px;

        &--dark {
            background-color: rgba($brand-red, 0.2);
        }
    }

    &__missed-count {
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 0.53px;
        margin-bottom: 3px;
        font-weight: 600;
    }

    &__missed-label {
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.28px;
        color: $slate-03;

        &--dark {
            color: $pearl;
        }
    }
}
</style>