<template>
    <div class="question-review__container">
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="questionReview"
            :show-full-height="true"
            @close="showUpgradeSidePanel = false"
        />
        <KeywordDefinition
            v-if="showKeywordDefinition && visibleKeywordDefinition && questionContainer"
            :keywordDOMElement="keywordDOMElement"
            :keywordClickLocation="keywordClickLocation"
            :keyword="visibleKeywordDefinition.keyword"
            :definition="visibleKeywordDefinition.definition"
            :questionContainer="questionContainer"
            :serial="currentQuestion.serial"
            :keywordLocation="visibleKeywordDefinition.location"
            @close="showKeywordDefinition = false"
        />
        <QuizContainer
            ref="quizContainer"
            class="question-review"
            :show-side="showSideBar"
            :is-dark-mode="isDarkMode"
        >
            <template #header>
                <PocketButton
                    class="question-review__back-btn"
                    type="icon-yellow"
                    :is-dark-mode="isDarkMode"
                    @click="goBack"
                >
                    <Icon class="question-review__back-btn-icon" type="arrow" />
                    <template v-if="breakpoint !== 'black-bear'">
                        Back to 
                    </template>{{ currentQuizId ? 'Results' : 'Review' }}
                </PocketButton>
                <div class="question-review__frame-context">
                    {{ frameContext }}
                </div>
                <div class="question-review__pagination">
                    <Icon
                        v-if="breakpoint !== 'black-bear'"
                        class="question-review__top-previous-arrow"
                        :class="{
                            'question-review__top-previous-arrow--disabled': isPreviousArrowDisabled,
                        }"
                        :tabindex="isPreviousArrowDisabled ? -1 : 0"
                        type="paginationArrow"
                        aria-label="Previous question"
                        :aria-disabled="isPreviousArrowDisabled"
                        @click="clickPrevious"
                        @keydown.enter.stop.prevent="clickPrevious"
                        @mousedown.prevent
                    />
                    <span class="question-review__page-nums">
                        {{ questionIndex + 1 }} / {{ listOfQuestions[filterType].length }}
                    </span>
                    <Icon
                        v-if="breakpoint !== 'black-bear'"
                        class="question-review__top-next-arrow"
                        :class="{
                            'question-review__top-next-arrow--disabled': isNextArrowDisabled,
                        }"
                        :tabindex="isNextArrowDisabled ? -1 : 0"
                        type="paginationArrow"
                        aria-label="Next question"
                        :aria-disabled="isNextArrowDisabled"
                        @click="clickNext"
                        @keydown.enter.stop.prevent="clickNext"
                        @mousedown.prevent
                    />
                </div>
            </template>
            <template #side>
                <div v-if="showSideBar" class="question-review__side-bar">
                    <div class="question-review__side-bar-header">
                        Questions
                        <PocketButton
                            type="icon-yellow"
                            :is-dark-mode="isDarkMode"
                            :icon="(filterType !== 'all' || filterSubjects.length) ? 'filterActive' : 'filter'"
                            :aria-label="`${
                                (filterType !== 'all' || filterSubjects.length) ? 'Filters selected, ' : ''
                            }Show filters`"
                            @click="showFilterDropdown = !showFilterDropdown"
                            @keypress.enter="showFilterDropdown = !showFilterDropdown"
                            @mousedown.prevent
                        />
                        <FilterDropdown
                            v-if="showFilterDropdown"
                            class="question-review__filter-dropdown"
                            :default-question-type="filterType"
                            :default-subjects="filterSubjects"
                            aria-live="polite"
                            @filterChange="updateFilter"
                            @close="showFilterDropdown = false"
                        />
                    </div>
                    <div v-if="listOfQuestions[filterType].length" class="question-review__list">
                        <div
                            v-for="(q, index) in listOfQuestions[filterType]"
                            :key="q.serial"
                            tabindex="0"
                            class="question-review__list-prompt"
                            :class="{
                                'question-review__list-prompt--active': index === questionIndex,
                            }"
                            @click="listQuestionClick(q)"
                            @keydown.enter.stop.prevent="listQuestionClick(q)"
                            @mousedown.prevent
                            v-html="q.scenarioPartLabel ? 
                                `${q.scenarioPartLabel}: ${q.prompt}` :
                                q.prompt"
                        />
                    </div>
                    <div v-else class="question-review__list-empty">
                        Looks like there aren’t any questions to match your filter criteria.
                    </div>
                </div>
            </template>
            <template #question>
                <Icon
                    v-if="isLoading"
                    v-dark
                    class="question-review__loading"
                    type="loading2"
                    :is-dark-mode="isDarkMode"
                />
                <Question
                    v-else-if="currentQuestion && currentExamMetadata"
                    :key="`${currentQuestion.objectId}-${allowKeyboardShortcuts}`"
                    class="question-review__question"
                    :ref="`questionContainer_${currentQuestion.objectId}`"
                    :container-el="quizContainerEl"
                    :question="currentQuestion"
                    :previous-choices="previousChoices"
                    :previous-matrix-choices="previousChoices"
                    :is-dark-mode="isDarkMode"
                    :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                    :image-url-prefix="`${s3Url || ''}/${currentQuestion.compositeKey.toUpperCase()}/`"
                    :review-mode="true"
                    :default-show-explanation="
                        (breakpoint === 'black-bear' || breakpoint === 'brown-bear') ? false : true
                    "
                    :global-metrics="showGlobalMetrics && currentGlobalMetric"
                    :auto-focus-prompt="true"
                    :show-paywall="showPaywall"
                    :hide-references="currentExamMetadata.hideReferences"
                    :keyword-definitions="keywordDefinitions"
                    @upgrade="showUpgradeSidePanel = true"
                    @keywordClick="keywordClicked"
                    @close="goBack"
                    @previousQuestion="clickPrevious"
                    @nextQuestion="clickNext"
                >
                    <template #explanationTopExperiment>
                        <KeywordDefinitionBanner
                            :isDarkMode="isDarkMode"
                            v-if="[0, 4, 7].includes(seenReviewWithKeywordsCount)
                                && !hasClickedKeyword
                                && !hasSeenHighlightedKeyword
                                && keywordDefinitions.length"
                        />
                    </template>
                    <template #context>
                        <div v-dark class="question-review__subject">
                            {{ currentQuestionSubject }}
                        </div>
                    </template>
                </Question>
                <div v-else class="question-review__no-question">
                    <div class="question-review__no-question-title">
                        Nothing to show here.
                    </div>
                    <div class="question-review__no-question-subtext">
                        Select a question and let’s get back to studying.
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="breakpoint !== 'black-bear'" class="question-review__view-toggles">
                    <div
                        class="question-review__side-toggle"
                    >
                        <div
                            v-dark
                            class="question-review__view-side"
                            :class="{
                                'question-review__view-side--active': showSideBar,
                                'question-review__view-side--hover': listViewHover,
                            }"
                            tabindex="0"
                            role="radio"
                            aria-label="Show List View"
                            :aria-checked="showSideBar"
                            @mouseenter="listViewMouseEnter"
                            @mouseleave="listViewMouseLeave"
                            @mousedown.prevent
                            @focus="showListViewTooltip = true"
                            @blur="showListViewTooltip = false"
                            @click="showSideBar = true"
                            @keydown.enter.stop.prevent="showSideBar = true"
                        >
                            <Tooltip
                                v-if="showListViewTooltip"
                                :is-dark-mode="isDarkMode"
                                :styles="{
                                    'backgroundColor': brandColors.grayDivider,
                                    'color': brandColors.brandBlack,
                                }"
                            >
                                List View
                            </Tooltip>
                            <Icon
                                v-if="showSideBar || listViewHover"
                                class="question-review__view-side-active-icon"
                                type="sideBarActive"
                            />
                            <Icon
                                v-else
                                class="question-review__view-side-icon"
                                type="sideBar"
                            />
                        </div>
                        <div
                            v-dark
                            class="question-review__view-full"
                            :class="{
                                'question-review__view-full--active': !showSideBar,
                                'question-review__view-full--hover': fullViewHover,
                            }"
                            tabindex="0"
                            role="radio"
                            aria-label="Show Full View"
                            :aria-checked="!showSideBar"
                            @mouseenter="fullViewMouseEnter"
                            @mouseleave="fullViewMouseLeave"
                            @mousedown.prevent
                            @focus="showFullViewTooltip = true"
                            @blur="showFullViewTooltip = false"
                            @click="showSideBar = false"
                            @keydown.enter.stop.prevent="showSideBar = false"
                        >
                            <Tooltip
                                v-if="showFullViewTooltip"
                                :styles="{
                                    'backgroundColor': brandColors.grayDivider,
                                    'color': brandColors.brandBlack,
                                }"
                            >
                                Full View
                            </Tooltip>
                            <Icon
                                v-if="!showSideBar || fullViewHover"
                                class="question-review__view-full-active-icon"
                                type="fullViewActive"
                            />
                            <Icon
                                v-else
                                class="question-review__view-full-icon"
                                type="fullView"
                            />
                        </div>
                    </div>
                    <div>
                        <GlobalMetricsToggle
                            :disabled="showPaywall"
                            class="question-review__global-metrics-toggle-left"
                            :show-global-metrics="showGlobalMetrics" 
                            :is-dark-mode="isDarkMode"
                            @toggleGlobalMetrics="toggleGlobalMetrics"
                        />
                    </div>
                </div>
                <div class="question-review__controls">
                    <Icon
                        class="question-review__previous-arrow"
                        :class="{
                            'question-review__previous-arrow--disabled': isPreviousArrowDisabled,
                        }"
                        :tabindex="isPreviousArrowDisabled ? -1 : 0"
                        type="paginationArrow"
                        aria-label="Previous question"
                        :aria-disabled="isPreviousArrowDisabled"
                        @click="clickPrevious"
                        @keydown.enter.stop.prevent="clickPrevious"
                        @mousedown.prevent
                    />
                    <GlobalMetricsToggle
                        v-if="breakpoint === 'black-bear'"
                        :disabled="showPaywall"
                        class="question-review__global-metrics-toggle-center"
                        :show-global-metrics="showGlobalMetrics" 
                        :is-dark-mode="isDarkMode"
                        @toggleGlobalMetrics="toggleGlobalMetrics"
                    />
                    <FlagToggle
                        :key="`flagToggle_${allowKeyboardShortcuts}`"
                        class="question-review__flag-toggle"
                        :is-flagged="isFlagged"
                        :enable-flag-tooltip="breakpoint !== 'brown-bear' && breakpoint !== 'black-bear'"
                        :enable-flag-keyboard-shortcut="allowKeyboardShortcuts"
                        @toggleFlag="toggleFlag"
                    />
                    <Icon
                        tabindex="0"
                        class="question-review__next-arrow"
                        :class="{
                            'question-review__next-arrow--disabled': isNextArrowDisabled,
                        }"
                        type="paginationArrow"
                        aria-label="Next question"
                        :aria-disabled="isNextArrowDisabled"
                        @click="clickNext"
                        @keydown.enter.stop.prevent="clickNext"
                        @mousedown.prevent
                    />
                </div>
                <KeyboardShortcutsButton
                    class="question-review__keyboard-shortcuts-btn"
                    :is-dark-mode="isDarkMode"
                    :tooltip-theme="
                        (breakpoint === 'polar-bear' || breakpoint === 'grizzly-bear' || breakpoint === 'brown-bear') 
                            && 'leftalign'
                    "
                >
                    <template
                        #keyboardShortcutsModal="{ 
                            showKeyboardShortcutsModal,
                            closeModalFunc
                        }"
                    >
                        <Portal to="modal">
                            <KeyboardShortcutsModal
                                v-if="showKeyboardShortcutsModal"
                                key="questionReviewKeyboardShortcutsModal"
                                keyboard-mode="review"
                                :allow-keyboard-shortcuts="allowKeyboardShortcuts"
                                :is-dark-mode="isDarkMode"
                                aria-live="polite"
                                @toggleKeyboardShortcuts="toggleKeyboardShortcuts"
                                @close="closeModalFunc"
                            />
                        </Portal>
                    </template>
                </KeyboardShortcutsButton>
            </template>
        </QuizContainer>
    </div>
</template>

<script lang="ts">
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import UIKit from '@pocketprep/ui-kit'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { screenModule } from '@/store/screen/module'
import FilterDropdown from '@/components/QuestionReview/FilterDropdown.vue'
import { qotdModule } from '@/store/qotd/module'
import type { Study } from '@pocketprep/types'
import { subscriptionModule } from '@/store/subscription/module'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { keywordsModule } from '@/store/keywords/module'
import { analyticsModule } from '@/store/analytics/module'
import KeywordDefinition from '@/components/KeywordDefinitions/KeywordDefinition.vue'
import KeywordDefinitionHint from '@/components/KeywordDefinitions/KeywordDefinitionHint.vue'
import KeywordDefinitionBanner from '@/components/KeywordDefinitions/KeywordDefinitionBanner.vue'
import { stripHtmlTags } from '@/utils'

type TQuestionRow = {
    serial: string
    prompt: string
    isFlagged: boolean
    isCorrect: boolean
    scenarioPartLabel: string
}

type TQuestionsLib = {
    [key: string]: TQuestionRow[]
}

@Component({
    components: {
        QuizContainer: UIKit.QuizContainer,
        PocketButton: UIKit.Button,
        Question: UIKit.Question,
        FlagToggle: UIKit.FlagToggle,
        KeyboardShortcutsButton: UIKit.KeyboardShortcutsButton,
        Icon: UIKit.Icon,
        GlobalMetricsToggle: UIKit.GlobalMetricsToggle,
        Tooltip: UIKit.Tooltip,
        KeyboardShortcutsModal: UIKit.KeyboardShortcutsModal,
        FilterDropdown,
        UpgradeSidePanel,
        KeywordDefinition,
        KeywordDefinitionHint,
        KeywordDefinitionBanner,
    },
})
export default class QuestionReview extends Vue {
    isLoading = true
    showSideBar = true
    showGlobalMetrics = false
    listViewHover = false
    fullViewHover = false
    showListViewTooltip = false
    showFullViewTooltip = false
    activeIndicatorTop = 0
    filterSubjects: string[] = []
    filterType = 'all'
    showFilterDropdown = false
    brandColors = BrandColors
    isFlagged = false
    showUpgradeSidePanel = false
    keywordDOMElement: HTMLElement | null = null
    keywordClickLocation: { x: number; y: number} | null = null
    showKeywordDefinition = false
    visibleKeywordDefinition: {
        keyword: string
        definition: string
        location: string
    } | null = null
    hasSeenHighlightedKeyword = false

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get showPaywall () {
        return !this.hasActiveSubscription && !this.currentQuestion?.isFree && !this.isTodaysQotD
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get isTodaysQotD () {
        return qotdModule.getters.getQotDQuestion()?.serial === this.currentQuestion?.serial
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get questionContainer () {
        const questionContainerRef = this.$refs[`questionContainer_${
            this.currentQuestion.objectId}`] as typeof UIKit.Question | undefined
        return questionContainerRef && '$el' in questionContainerRef ? questionContainerRef.$el as HTMLElement : null
    }

    get quizContainerEl () {
        const quizContainerRef = this.$refs['quizContainer'] as typeof UIKit.QuizContainer | undefined
        return (quizContainerRef && '$el' in quizContainerRef) ? quizContainerRef.$el : null
    }

    get s3Url () {
        return import.meta.env.VUE_APP_S3_URL
    }

    get frameContext () {
        return this.filterType === 'all'
            ? 'All Answered'
            : this.filterType
    }

    get currentQuiz () {
        if (this.currentQuizId) {
            return quizModule.getters.getQuizById(this.currentQuizId) || null
        } else {
            return null
        }
    }

    get isMockExam () {
        return this.currentQuiz?.mode === 5
    }

    get answers () {
        if (this.currentQuizId && this.isMockExam) {
            return quizModule.getters.getLatestAnswers({ quizId: this.currentQuizId, questionFilter: 'mock' })
        } else if (this.currentQuizId) {
            return quizModule.getters.getLatestAnswers({ quizId: this.currentQuizId, questionFilter: 'all' })
        } else {
            return quizModule.getters.getLatestAnswers({ questionFilter: 'all' })
        }
    }

    get currentAnswer () {
        return this.currentQuestion && this.answers[this.currentQuestion.serial]
    }

    get previousChoices () {
        return this.currentAnswer?.selectedChoices || []
    }

    get questions () {
        return quizModule.getters.getAnsweredQuestions().reduce<{ [serial: string]: Study.Class.QuestionJSON }>(
            (acc, q) => {
                acc[q.serial] = q
                return acc
            }, {})
    }

    get seenReviewWithKeywordsCount () {
        return userModule.state.user?.webConfig?.seenReviewWithKeywordsCount || 0
    }

    get hasClickedKeyword () {
        return userModule.state.user?.webConfig?.hasClickedKeyword
    }

    get isMobileOrTabletScreen () {
        return this.breakpoint === 'black-bear' || this.breakpoint === 'brown-bear'
    }

    get questionSerials () {
        if (this.currentQuizId) {
            return this.currentQuizQuestionSerials
        }

        return Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
            .sort((a, b) => b.answerDate - a.answerDate)
    }

    get answeredQuestionsSerialLib () {
        const lib = quizModule.getters.getAnsweredQuestions().reduce((acc, q) => {
            if (!acc[q.serial]) {
                acc[q.serial] = q
            }
            return acc
        }, {} as { [serial: string]: Study.Class.QuestionJSON })

        return lib
    }

    get listQuestions () {
        const sortedBestAnswers = Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
            .sort((a, b) => b.answerDate - a.answerDate)

        return sortedBestAnswers.map(q => {
            const question = this.answeredQuestionsSerialLib[q.questionSerial]
            return {
                serial: question?.serial,
                prompt: question?.prompt.replace(/<[^\s>]+[^>]*>/gi, ' '),
            }
        })
    }

    get flaggedQuestionSerials () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()?.flaggedQuestions || []
    }

    get quizOrReviewQuestions () {
        if (this.currentQuizId) {
            return quizModule.getters.getQuizById(this.currentQuizId)?.answers.map(a => a) || []
        }

        return Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
            .sort((a, b) => b.answerDate - a.answerDate)
    }

    get listOfQuestions () {
        return this.quizOrReviewQuestions.reduce<TQuestionsLib>((acc, answer) => {
            const serial = answer.questionSerial
            const question = this.answeredQuestionsSerialLib[serial]
            if (!question) {
                return acc
            }

            const isFlagged = !!this.flaggedQuestionSerials.includes(serial)
            const isCorrect = answer.isCorrect
            const subject = question.subject as Study.Class.SubjectJSON

            const prompt = stripHtmlTags(question.prompt)
            const scenarioPartLabel = this.scenarioPartLabel(question)

            if (this.filterSubjects.length && !this.filterSubjects.includes(subject.name)) {
                return acc
            }

            const questionObject = {
                serial,
                prompt,
                isFlagged,
                isCorrect,
                scenarioPartLabel,
            }

            if (isFlagged) {
                acc.flagged?.push(questionObject)
            }
            if (isCorrect) {
                acc.correct?.push(questionObject)
            }
            if (!isCorrect) {
                acc.incorrect?.push(questionObject)
            }

            acc.all?.push(questionObject)
            return acc
        }, { all: [], flagged: [], correct: [], incorrect: [] })
    }

    get currentQuizId () {
        return this.$route.query.quizId as string
    }

    get currentSerial () {
        return this.$route.query.questionSerial as string
    }

    get currentQuizQuestionSerials () {
        return quizModule.getters.getQuizById(this.currentQuizId)?.answers.map(a => a.questionSerial) || []
    }

    get previousQuestion () {
        return this.listOfQuestions[this.filterType][this.questionIndex - 1]
    }

    get currentQuestion () {
        return this.questions[this.currentSerial]
    }

    get currentQuestionSubject () {
        const subject = this.currentQuestion.subject as Study.Class.SubjectJSON
        return subject.name
    }

    get nextQuestion () {
        return this.listOfQuestions[this.filterType][this.questionIndex + 1]
    }

    get questionIndex () {
        return this.listOfQuestions[this.filterType].findIndex(q => q.serial === this.currentSerial)
    }

    get isPreviousArrowDisabled () {
        return this.questionIndex <= 0
    }

    get isNextArrowDisabled () {
        return this.questionIndex >= (this.listOfQuestions[this.filterType].length - 1)
    }

    get currentGlobalMetric () {
        if (!this.globalMetricsBySerial) {
            return null
        } else {
            return this.globalMetricsBySerial[this.currentSerial]
        }
    }

    get globalMetricsBySerial () {
        return globalQuestionMetricModule.getters.getGlobalQuestionMetricsBySerial()
    }

    get flaggedQuestions () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
    }

    get quizSettings () {
        return userModule.state.user?.quizSettings || null
    }

    get allowKeyboardShortcuts () {
        return !!this.quizSettings?.enableKeyboardShortcuts
    }

    get keywordDefinitions () {
        const questionSerial = this.currentQuestion?.serial
        const questionKeywords = keywordsModule.getters.getKeywordDefinitions().get(questionSerial)

        if (
            !this.currentAnswer 
            || !questionKeywords
            || !this.currentAnswer
        ) {
            return []
        }

        return Array.from(questionKeywords, ([ keyword, definition ]) => ({
            keyword,
            definition,
        }))
    }

    async mounted () {
        await Promise.all([
            examMetadataModule.actions.fetchExamMetadata(),
            userModule.actions.fetchUserData(),
            quizModule.actions.fetchAnsweredQuestions(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
        ])

        if (this.$route.query.subject) {
            if (typeof this.$route.query.subject === 'string') {
                this.filterSubjects = [ this.$route.query.subject ]
            } else {
                this.filterSubjects = this.$route.query.subject as string[]
            }
        }

        if (typeof this.$route.query.type === 'string') {
            this.filterType = this.$route.query.type.toLowerCase()
        }

        await keywordsModule.actions.fetchKeywordDefinitions([
            ...this.currentQuizQuestionSerials,
            ...Object.keys(this.answeredQuestionsSerialLib),
        ])

        const questionSerial = this.currentQuestion?.serial
        this.isFlagged = !!(questionSerial && this.flaggedQuestions.includes(questionSerial))

        // close side panel on `esc` press
        window.addEventListener('keydown', this.keydownListener, true)

        this.isLoading = false
    }

    async beforeUnmount () {
        window.removeEventListener('keydown', this.keydownListener, true)
        
        // if user was exposed to a highlighted keyword, update webConfig item
        if (this.hasSeenHighlightedKeyword || this.keywordDefinitions) {
            userModule.actions.updateWebConfig({
                seenReviewWithKeywordsCount: this.seenReviewWithKeywordsCount + 1,
            })
        }
    }

    keydownListener (e: KeyboardEvent) {
        if (e.key === 'Escape' && !this.showFilterDropdown) {
            e.stopPropagation()
            e.preventDefault()
            this.goBack()
        }
    }

    goBack () {
        this.$router.push(
            this.currentQuizId
                ? { 
                    name: 'quiz-result', 
                    params: { 
                        quizId: this.currentQuizId,
                    },
                    query: this.$route.query,
                }
                : { name: 'review', query: this.$route.query }
        )
    }

    toggleGlobalMetrics (newVal: boolean) {
        this.showGlobalMetrics = newVal
    }

    clickPrevious () {
        if (this.previousQuestion) {
            this.$router.replace({
                name: 'question-review',
                query: {
                    ...this.$route.query,
                    questionSerial: this.previousQuestion.serial,
                },
            })
        }

        if (this.keywordDefinitions.length) {
            this.hasSeenHighlightedKeyword = true
        }
    }

    clickNext () {
        if (this.nextQuestion) {
            this.$router.replace({
                name: 'question-review',
                query: {
                    ...this.$route.query,
                    questionSerial: this.nextQuestion.serial,
                },
            })
        }

        if (this.keywordDefinitions.length) {
            this.hasSeenHighlightedKeyword = true
        }
    }

    keywordClicked (params?: { 
        keyword: string
        location: string
        clickLocation: { x: number; y: number}
        target: HTMLElement
    }) {
        this.showKeywordDefinition = false
        if (!params?.keyword) {
            return
        }

        const questionKeywords = keywordsModule.getters.getKeywordDefinitions().get(this.currentQuestion.serial)
        const definition = questionKeywords?.get(params.keyword.toLowerCase())

        if (!definition) {
            return
        }

        analyticsModule.actions.amplitudeTrack('Keyword Definition Viewed', {
            examGuid: this.currentExamMetadata?.examGuid,
            definitionKeyword: params.keyword,
            serial: this.currentQuestion.serial,
            location: params.location,
            quizMode: this.currentQuiz?.mode ?? 10000,
            studyMode: 'Review',
        })

        this.visibleKeywordDefinition = {
            keyword: params.keyword, 
            definition,
            location: params.location,
        }
        this.keywordDOMElement = params.target
        this.keywordClickLocation = params.clickLocation

        userModule.actions.updateWebConfig({ hasClickedKeyword: true })

        this.$nextTick(() => {
            this.showKeywordDefinition = true
        })
    }

    async toggleFlag () {
        this.isFlagged = !this.isFlagged
        const serial = this.currentQuestion?.serial

        try {
            await userExamMetadataModule.actions.toggleQuestionFlag(serial)
        } catch (e) {
            // noop
        } finally {
            this.isFlagged = !!(serial && this.flaggedQuestions.includes(serial))
        }
    }

    toggleKeyboardShortcuts (newVal: boolean) {
        userModule.actions.updateQuizSettings({
            enableKeyboardShortcuts: newVal,
        })
    }

    /* istanbul ignore next */
    listViewMouseEnter () {
        this.listViewHover = true
        this.showListViewTooltip = true
    }

    /* istanbul ignore next */
    listViewMouseLeave () {
        this.listViewHover = false
        this.showListViewTooltip = false
    }

    /* istanbul ignore next */
    fullViewMouseEnter () {
        this.fullViewHover = true
        this.showFullViewTooltip = true
    }

    /* istanbul ignore next */
    fullViewMouseLeave () {
        this.fullViewHover = false
        this.showFullViewTooltip = false
    }

    listQuestionClick (q: QuestionReview['listQuestions'][number]) {
        if (q.serial !== this.currentSerial) {
            this.$router.replace({
                name: 'question-review',
                query: {
                    ...this.$route.query,
                    questionSerial: q.serial,
                },
            })
        }
        if (this.keywordDefinitions.length) {
            this.hasSeenHighlightedKeyword = true
        }
    }

    updateFilter ({ questionType, subjects }: { questionType: string; subjects: string[] }) {
        this.filterType = questionType
        this.filterSubjects = subjects
        this.$router.replace({
            name: 'question-review',
            query: {
                ...this.$route.query,
                subject: this.filterSubjects,
                type: this.filterType.charAt(0).toUpperCase() + this.filterType.slice(1),
                questionSerial: this.questionIndex === -1
                    ? this.listOfQuestions[this.filterType].length
                        ? this.listOfQuestions[this.filterType][0].serial
                        : undefined
                    : this.$route.query.questionSerial,
            },
        })
    }

    scenarioPartLabel (question: Study.Class.QuestionJSON) {
        return questionModule.getters.getQuestionScenarioPartLabel(question)
    }

    @Watch('currentQuestion')
    currentQuestionChanged () {
        this.isFlagged = !!this.currentQuestion && this.flaggedQuestions.includes(this.currentQuestion.serial)
    }
}
</script>

<style lang="scss" scoped>
.question-review {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &__back-btn-icon {
        width: 14px;
        height: 12px;
        transform: rotate(180deg);
        margin-right: 8px;
    }

    &__frame-context {
        position: absolute;
        text-transform: capitalize;
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        font-size: 15px;
        letter-spacing: -0.09px;
        line-height: 18px;
    }

    &__subject {
        width: 100%;
        max-width: 492px;
        color: $slate-01;
        margin-top: 48px;
        margin-bottom: 8px;
        padding: 0 16px;
        box-sizing: border-box;
        font-size: 14px;

        &--dark {
            color: $pewter;
        }

        @include breakpoint(black-bear) {
            padding-left: 15px;
            box-sizing: border-box;
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
    }

    &__page-nums {
        margin: 0 7px;
    }

    &__side-bar {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__filter-dropdown {
        position: absolute;
        left: 0;
        top: 44px;
        z-index: 2;
    }

    &__active-indicator {
        position: absolute;
        right: -10px;
        transform: translateY(-50%);
        width: 10px;
        height: 18px;
        background-color: $ash;
        border: 5px solid white;
    }

    &__side-bar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding: 0 6px 0 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    &__list {
        position: relative;
        overflow-y: auto;
        flex: 1;
    }

    &__list-empty {
        color: $white;
        font-size: 13px;
        max-width: 160px;
        margin: 58px auto 0;
        line-height: 18px;
        opacity: 0.82;
        text-align: center;
    }

    &__list-prompt {
        position: relative;
        box-sizing: border-box;
        color: rgba($white, 0.75);
        padding: 14px 11px 14px 12px;
        font-size: 14px;
        letter-spacing: -0.09px;
        line-height: 17px;
        cursor: pointer;
        border-top: 2px solid $jet;
        outline: none;

        &:last-child {
            border-bottom: 2px solid $mariner;
        }

        &--active {
            color: $white;
            background-color: $ash;
            border-radius: 6px;
            border-color: $charcoal;

            &:last-child {
                border-bottom: 0;
            }
        }

        &--active + & {
            border-top-color: $charcoal;
        }

        &:hover {
            color: $white;
        }

        &:focus {
            color: $white;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 6px;
                border: 1px solid $butterscotch;
            }
        }
    }

    &__loading {
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translateX(-50%) scale(1.5);
    }

    &__question {
        position: relative;
        height: 100%;
    }

    &__no-question {
        max-width: 242px;
        margin: 158px auto 0;
        text-align: center;
    }

    &__no-question-title {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 9px;
        font-weight: 600;
    }

    &__no-question-subtext {
        font-size: 14px;
        line-height: 17px;
        max-width: 242px;
        color: $ash;
    }

    &__view-toggles {
        display: flex;
        align-items: center;
    }

    &__side-toggle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80px;
        height: 37px;
        margin-right: 25px;
        border-radius: 6px;

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            right: -13px;
            border-right: 1px solid $slate-03;
        }

        @include breakpoint(black-bear) {
            position: static;
        }
    }

    &__view-side,
    &__view-full {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 37px;
        border-radius: 6px;
        outline: none;

        &--hover {
            background-color: rgba($white, 0.1);
        }

        &--active {
            background-color: $charcoal;
            z-index: 2;

            &--dark {
                background-color: transparent;
            }
        }

        &:focus::before {
            content: '';
            position: absolute;
            border: 1px solid $butterscotch;
            width: 40px;
            height: 36px;
            top: 0;
            left: 0;
            border-radius: 4px;
            box-sizing: border-box;
        }

        &:not(&--active) {
            cursor: pointer;
        }

        .uikit-tooltip {
            position: absolute;
            left: calc(50%);
            top: calc(-100%);
        }
    }

    &__view-side {
        left: 0;

        &--hover {
            &:not(&--active) {
                padding-right: 6px;
                border-radius: 6px 0 0 6px;
            }

            &--dark {
                &:not(&--active) {
                    padding-right: 0;
                    border-radius: 6px;
                }
            }
        }
    }

    &__view-full {
        right: 0;

        &--hover {
            &:not(&--active) {
                padding-left: 6px;
                border-radius: 0 6px 6px 0;

                &:focus::before {
                    left: 6px;
                }
            }

            &--dark {
                &:not(&--active) {
                    padding-left: 0;
                    border-radius: 6px;
                }
            }
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(black-bear) {
            justify-content: space-between;
            width: 304px;
        }
    }

    &__previous-arrow,
    &__next-arrow,
    &__top-previous-arrow,
    &__top-next-arrow {
        &--disabled {
            opacity: 0.35;
        }

        &:not(&--disabled) {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    color: $banana-bread;
                }
            }
        }

        &:focus {
            outline: none;
            color: $banana-bread;
        }
    }

    &__top-previous-arrow,
    &__top-next-arrow {
        width: 24px;
        height: 24px;
    }

    &__previous-arrow,
    &__top-previous-arrow {
        transform: rotate(180deg);
    }

    &__global-metrics-toggle-center {
        margin-left: 62px;

        @include breakpoint(black-bear) {
            margin: 0;
        }
    }

    &__flag-toggle {
        margin: 0 80px;

        @include breakpoint(black-bear) {
            margin: 0;
        }
    }

    &__keyboard-shortcuts-btn {
        position: absolute;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    :deep(.uikit-quiz-container__main) {
        @include breakpoint(black-bear) {
            height: calc(100% - 99px);
        }
    }

    :deep(.uikit-question__close-icon) {
        display: none;
    }
}
</style>
