<template>
    <div class="upgrade">
        <AddPaymentMethodSidePanel 
            v-if="showAddPaymentMethodSidePanel"
            key="upgradeSidePanel"
            :plan-amount="planAmount"
            :disable-transition="true"
            :show-full-height="showFullHeight"
            @close="showAddPaymentMethodSidePanel = false; disableTransition = true"
        />
        <Portal
            v-if="
                !showAddPaymentMethodSidePanel
                    && isAuthRoute
                    && hasSeenWelcomeModal
                    && !hasActiveSubscription
            "
            to="sidepanel"
        >
            <SidePanel
                :key="`upgradeSidePanel-${String($.vnode.key)}`"
                width="medium"
                name="Upgrade to Premium"
                :disable-transition="disableTransition"
                :is-dark-mode="isDarkMode"
                :class="{ 'upgrade--full-height': showFullHeight }"
                class="upgrade__sidepanel"
                @close="cancelUpgrade"
            >
                <Banner
                    v-if="validReferral && (pmType && pmType.value !=='license')"
                    :is-dark-mode="isDarkMode"
                    class="upgrade__banner"
                >
                    <template #bannerText>
                        <template v-if="'name' in validReferral && validReferral.name">
                            {{ validReferral.name }}
                        </template>
                        <template v-else-if="'objectId' in validReferral">
                            Someone sent you 20% off your first subscription with Pocket Prep. Sweet!
                        </template>
                    </template>
                </Banner>
                <Errors
                    v-if="(!isLoading && referralError) && (pmType && pmType.value !=='license')"
                    :is-dark-mode="isDarkMode"
                    :errors="[ referralError ]"
                    class="upgrade__error"
                />
                <Errors
                    v-if="errors.length && (pmType && pmType.value ==='license')"
                    class="upgrade__error"
                    :is-dark-mode="isDarkMode"
                    :errors="errors"
                />
                <div
                    v-dark
                    class="upgrade__label" 
                    :class="{ 
                        'upgrade__label--premium-referral-error': referralError && pmType && pmType.value !=='license',
                        'upgrade__label--license-code-error': errors.length && pmType && pmType.value ==='license'
                    }"
                >
                    Show up confident on test day
                </div>
                <div v-dark class="upgrade__upgrade-info-section">
                    <div v-dark class="upgrade__upgrade-info">
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/register-onboard/question.svg"
                            class="upgrade__question-svg"
                            alt=""
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/register-onboard/question-dark.svg"
                            class="upgrade__question-svg"
                            alt=""
                        >
                        <div 
                            v-if="examMetadata" 
                            v-dark 
                            class="upgrade__upgrade-message"
                        >
                            <b>{{ examMetadata.itemCount - examMetadata.archivedCount }} questions</b> 
                            and explanations written by subject matter experts.
                        </div>
                    </div>
                    <div 
                        v-if="examMetadata" 
                        v-dark 
                        class="upgrade__upgrade-info"
                    >
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/register-onboard/subject-insights.svg"
                            class="upgrade__subject-insights-svg"
                            alt=""
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/register-onboard/subject-insights-dark.svg"
                            class="upgrade__subject-insights-svg"
                            alt=""
                        >
                        <div v-dark class="upgrade__upgrade-message">
                            <b>Multiple quiz modes</b>
                            {{ examMetadata.mockExams && examMetadata.mockExams.length > 1 ?  
                                'and mock exams to gauge your exam readiness.' :  
                                examMetadata.mockExams && examMetadata.mockExams.length === 1 ?
                                    'and a mock exam to gauge your exam readiness.' :
                                    'to gauge your exam readiness.'
                            }}
                        </div>
                    </div>
                    <div v-dark class="upgrade__upgrade-info">
                        <img
                            v-if="!isDarkMode"
                            src="@/assets/register-onboard/pass-guarantee.svg"
                            class="upgrade__pass-guarantee-svg"
                            alt=""
                        >
                        <img
                            v-if="isDarkMode"
                            src="@/assets/register-onboard/pass-guarantee-dark.svg"
                            class="upgrade__pass-guarantee-svg"
                            alt=""
                        >
                        <div v-dark class="upgrade__upgrade-message">
                            <b>Pass Guarantee:</b> If you fail your exam, we’ll give you three months free.*
                        </div>
                    </div>
                </div>
                <div class="upgrade__compare">
                    <PocketButton
                        type="tertiary-small"
                        class="upgrade__compare-link"
                        :class="{ 'upgrade__compare-link--open': showCompare }"
                        :is-dark-mode="isDarkMode"
                        @click="toggleCompareView"
                    >
                        {{ showCompare ? 'Hide Premium Details' : 'View Premium Details' }}
                        <Icon type="accordionArrow" />
                    </PocketButton>
                    <UpgradeCompare
                        v-if="showCompare"
                        :exam="examMetadata"
                        :bundle="bundle"
                        :is-dark-mode="isDarkMode"
                        class="upgrade__compare-table"
                    />
                </div>
                <div class="upgrade__payment-label">
                    Payment Method
                </div>
                <PocketSelect
                    v-if="pmType.value === 'card'"
                    v-model="plan"
                    label="Payment Plan"
                    class="upgrade__plan"
                    :data="planOptions"
                    :is-dark-mode="isDarkMode"
                    :subtext="plan && validReferral"
                />
                <div 
                    v-if="renewDate && (pmType && pmType.value !=='license') && !validReferral" 
                    class="upgrade__renew-date"
                >
                    Renews {{renewDate}}
                </div>
                <AppleGooglePay
                    v-if="plan && planAmount"
                    class="upgrade__apple-google-pay"
                    :plan-amount="planAmount"
                    :plan-subscription-name="planSubscriptionName"
                    :plan="plan"
                    :referral-id="referralId"
                    :discount-plan-amount="discountPlanAmount"
                    :impact-click-id="impactClickId"
                    :errors="appleGooglePayErrors"
                    :error-fields="appleGooglePayErrorFields"
                    @update="isAppleGooglePayAvailable"
                    @submitAppleGooglePayment="submitAppleGooglePayment"
                />
                <div class="upgrade__pm-options">
                    <Radio
                        v-model="pmType"
                        :is-dark-mode="isDarkMode"
                        :data="[
                            { label: 'Credit Card', value: 'card' },
                            { label: 'License Code', value: 'license' },
                        ]"
                    />
                </div>
                <PocketSelect
                    v-if="paymentMethods.length > 1 && pmType.value === 'card'"
                    v-model="paymentMethod"
                    label="Wallet"
                    class="upgrade__wallet"
                    :data="paymentMethods"
                    :is-dark-mode="isDarkMode"
                    @linkClick="addPaymentMethod"
                />
                <PaymentFields
                    v-else-if="!isLoading && pmType.value === 'card' && planAmount"
                    :errors="errors"
                    class="upgrade__fields"
                    :class="{ 'upgrade__fields--state-open': isStateDropdownOpen }"
                    :error-fields="errorFields"
                    :plan-amount="planAmount"
                    @openDropdown="openDropdown"
                    @submit="startPremium"
                    @update="updatePaymentParams"
                />
                <template v-if="pmType.value==='license'">
                    <PocketInput
                        v-model="firstName"
                        :is-dark-mode="isDarkMode"
                        label="First Name"
                        :error="errorFields.includes('firstName')"
                        class="upgrade__input"
                        @keydown.enter="startPremium"
                    />
                    <PocketInput
                        v-model="lastName"
                        :is-dark-mode="isDarkMode"
                        label="Last Name"
                        :error="errorFields.includes('lastName')"
                        class="upgrade__input"
                        @keydown.enter="startPremium"
                    />
                    <LicenseField
                        v-model="licenseKey"
                        class="upgrade__license"
                    />
                    <div class="upgrade__license-info">
                        License codes are purchased by your school or workplace. If you haven’t received a license code,
                        contact your organization.
                    </div>
                </template>
                <ReferralTerms
                    v-if="plan && validReferral && 'objectId' in validReferral && (pmType && pmType.value !=='license')"
                    :plan="plan"
                    utm-content="upgrade_referrals_terms"
                    @agreedChanged="agreedChanged"
                />
                <template #action>
                    <PocketButton
                        :is-dark-mode="isDarkMode"
                        :disabled="isDisabled"
                        :is-loading="isPaymentBeingProcessed"
                        @click="startPremium"
                    >
                        Start Premium
                    </PocketButton>
                </template>
            </SidePanel>
        </Portal>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit, Watch } from 'vue-facing-decorator'
import PaymentFields from '@/components/PaymentFields.vue'
import AppleGooglePay from '@/components/AppleGooglePay.vue'
import LicenseField from '@/components/Settings/ActivateLicense/LicenseField.vue'
import AddPaymentMethodSidePanel from '@/components/Settings/AddPaymentMethodSidePanel.vue'
import ReferralTerms from '@/components/ReferralTerms.vue'
import UpgradeCompare from '@/components/Settings/UpgradeCompare.vue'
import { isPromise } from '@/store/utils'
import { licenseModule } from '@/store/license/module'
import { stripeModule } from '@/store/stripe/module'
import { bundleModule } from '@/store/bundle/module'
import { subscriptionModule } from '@/store/subscription/module'
import { userModule } from '@/store/user/module'
import { analyticsModule } from '@/store/analytics/module'
import { referralModule } from '@/store/referral/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { stripeEnabledAppearance, stripeDisabledAppearance, formatDate } from '@/utils'
import { type StripeElement } from '@stripe/stripe-js'
import { isProxy, toRaw } from 'vue'
import * as Sentry from '@sentry/browser'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        Radio: UIKit.Radio,
        PaymentFields,
        AppleGooglePay,
        Icon: UIKit.Icon,
        PocketInput: UIKit.Input,
        LicenseField,
        PocketSelect: UIKit.Select,
        AddPaymentMethodSidePanel,
        ReferralTerms,
        Banner: UIKit.Banner,
        Errors: UIKit.Errors,
        UpgradeCompare,
    },
})
export default class UpgradeSidePanel extends Vue {
    @Prop({ default: false }) showFullHeight!: boolean
    @Prop() quizMode!: 'weakest' | 'missed'

    isLoading = true
    pmType: { label: string; value: 'card' | 'license' } = { label: 'Credit Card', value: 'card' }
    errors: string[] = []
    appleGooglePayErrors: string[] = []
    appleGooglePayErrorFields: string[] = []
    isStateDropdownOpen = false
    errorFields: string[] = []
    plan: null | { value: string; label: string } = null
    stripe: stripe.Stripe | null = null
    showAddPaymentMethodSidePanel = false
    disableTransition = false
    showCompare = false
    licenseKey: null | string = null
    firstName = ''
    lastName = ''
    paymentMethod: null | { value: string; label: string } = null
    agreeDisabled = false
    impactClickId = ''
    addressEl: StripeElement | null | void = null
    cardElCompleted = false
    addressElCompleted = false
    paymentFieldsCompleted = false
    planAmount: null | number = null
    planSubscriptionName = ''
    appleGooglePayAvailable = false
    isPaymentBeingProcessed = false
    version = import.meta.env.VUE_APP_VERSION

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get bundleUniqueExams () {
        return this.bundle && bundleModule.getters.getBundleUniqueExams(this.bundle?.objectId) || []
    }


    get isDisabled () {
        return !this.canStartPremium ||
            (this.validReferral && 'objectId' in this.validReferral && !this.agreeDisabled) ||
            (!this.paymentMethod && !this.paymentFieldsCompleted && !this.licenseKey) ||
            (this.pmType.value === 'license' && (!this.licenseKey || !this.firstName || !this.lastName)) ||
            this.errors.length ||
            this.isPaymentBeingProcessed
    }

    get canStartPremium () {
        return this.pmType.value === 'card'
            ? this.plan?.value
            : !!this.licenseKey
    }

    get paymentMethods () {
        const options = stripeModule.getters.getStripePaymentMethods()?.reduce((acc, pm) => {
            if (new Date(pm.card?.exp_year || 0, (pm.card?.exp_month || 0) + 1, 0).getTime() < new Date().getTime()) {
                return acc
            }
            const label = `Card ending in ${pm.card?.last4} (Exp ${pm.card?.exp_month}/${pm.card?.exp_year})`
            acc.push({
                value: pm.id,
                label,
            })

            return acc
        }, [] as { value: string; label: string}[])

        return [
            ...options,
            { value: 'add', label: '+ Add Payment Method', type: 'link' },
        ]
    }

    get examMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return bundleExam?.examGuid === this.examMetadata?.examGuid
            }))
    }

    get planOptions () {
        // referralDiscount is configurable at the state level
        return this.bundle && stripeModule.getters.getStripePlanOptions(this.bundle.objectId, true) || []
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get referralId () {
        return !this.validReferral
            ? ''
            : 'objectId' in this.validReferral 
                ? this.validReferral.objectId 
                : this.validReferral.id
    }
    
    get discountPlanAmount () {
        return (this.validReferral && this.planAmount)
            ? Math.round(referralModule.getters.calculateDiscountedPrice(this.planAmount))
            : ''
    }

    get isAuthRoute () {
        return this.$route.matched.some(route => route.meta?.requiresAuth)
    }

    get hasSeenWelcomeModal () {
        return userModule.state.user?.webConfig?.hasSeenWelcomeModal
            || userModule.state.user?.webConfig?.hasSeenOnboarding
    }

    get hasExpandedCompareTable () {
        return userModule.state.user?.webConfig?.hasViewedCompareTable
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get planInfo () {
        const statePlans = stripeModule.state.plans.value
        let intervalCount = 1
        let interval = 'month'
        let amount = null
        if (this.bundle) {
            if (isPromise(statePlans) || !statePlans || !statePlans[this.bundle.objectId]) {
                return null
            }
            const bundlePlans = statePlans[this.bundle.objectId]
            const bundlePlan = bundlePlans.find(plan => plan.id === this.plan?.value)
            intervalCount = bundlePlan ? bundlePlan.interval_count : 1
            interval = bundlePlan ? bundlePlan.interval : 'month'
            amount = bundlePlan?.amount
        }
        return {
            intervalCount,
            interval,
            amount,
        }
    }

    get subscriptionName () {
        if (this.planInfo) {
            if (this.planInfo.interval === 'month') {
                return this.planInfo.intervalCount === 1 ? 'Premium Prep Monthly' : 'Premium Prep Quarterly'
            }

            if (this.planInfo.interval === 'year' && this.planInfo.intervalCount === 1) {
                return 'Premium Prep Yearly'
            }
        }
        return ''
    }

    get renewDate () {
        if (this.planInfo?.interval && this.planInfo.intervalCount) {
            return formatDate(new Date(), this.planInfo.interval, this.planInfo.intervalCount)
        } else {
            return null
        }
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            stripeModule.actions.fetchStripePlans(),
            stripeModule.actions.fetchPaymentMethods(),
            userModule.actions.fetchUserData(),
        ])
        const planOption = this.planOptions[0]
        const paymentMethodOption = this.paymentMethods[0]
        if (!planOption) {
            Sentry.captureException(new Error('no planOptions bug with data'), {
                extra: {
                    bundleId: this.bundle?.objectId,
                    planOption: JSON.stringify(planOption),
                    planOptions: JSON.stringify(this.planOptions),
                    currentBundle: JSON.stringify(this.bundle),
                    stripePlans: JSON.stringify(stripeModule.state.plans.value),
                    currentExamMetadata: JSON.stringify(examMetadataModule.getters.getCurrentExamMetadata()),
                },
            })
            throw new Error('UpgradeSidePanel - mounted: no planOptions')
        }
        this.plan = planOption
        this.planAmount = this.planInfo?.amount ? this.planInfo.amount : null

        if (!paymentMethodOption) {
            throw new Error('UpgradeSidePanel - mounted: no paymentMethods')
        }
        this.paymentMethod = this.paymentMethods.length > 1 ? paymentMethodOption : null
        this.firstName = userModule.state.user?.firstName || ''
        this.lastName = userModule.state.user?.lastName || ''

        // check to see if there is a valid referral in the URL
        await referralModule.actions.validateReferral()

        if (import.meta.env.VUE_APP_IMPACT_UTT) {
            window.ire('generateClickId', (clickId: string) => {
                this.impactClickId = clickId
            })
        }

        this.planSubscriptionName = this.subscriptionName
        this.isLoading = false
    }

    async startPremium () {
        if (this.isPaymentBeingProcessed) {
            return
        }

        this.isPaymentBeingProcessed = true
        this.errors = []
        this.errorFields = []
        if (this.pmType.value === 'license') {
            // Should not get here because of the isDisabled get call 
            // but just in case it does throw an error 
            // and let the user know what field needs to be filled in
            if (!this.firstName) {
                this.errorFields.push('firstName')
                this.errors.push('Please fill in First Name.')
                this.isPaymentBeingProcessed = false
                throw new Error('Unable to process license code. Missing first name.')
            }
            if (!this.lastName) {
                this.errorFields.push('lastName')
                this.errors.push('Please fill in Last Name.')
                this.isPaymentBeingProcessed = false
                throw new Error('Unable to process license code. Missing last name.')
            }

            if (!this.errorFields.length && this.licenseKey) {
                const license = await licenseModule.actions.validateLicense(this.licenseKey)
                await Promise.all([
                    userModule.actions.updateUser({
                        firstName: this.firstName,
                        lastName: this.lastName,
                    }),
                    licenseModule.actions.activateLicense(license),
                ])
                await analyticsModule.actions.trackEvent('Upgrade_Viewed', {
                    result: 'License Activated',
                    ...(this.examMetadata && { exam: this.examMetadata.nativeAppName }),
                })
                this.emitClose()
            }
        } else {
            if (import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY && this.plan && this.planAmount) {
                const { stripe, elements } = await stripeModule.actions.loadStripeAndElements({
                    planAmount: this.planAmount,
                    creatingPaymentField: false,
                })

                if (stripe && elements) {
                    const disabledAppearance = stripeDisabledAppearance(this.isDarkMode)
                    const enabledAppearance = stripeEnabledAppearance(this.isDarkMode)
                    // disable card fields while processing
                    const paymentStripeEl = elements.getElement('payment')
                    paymentStripeEl?.update({ readOnly: true })
                    stripeModule.getters.getElements()?.update({ appearance: disabledAppearance })
                    // retrieves clientSecret and subscriptionId
                    const { clientSecret, subscriptionId } = await subscriptionModule.actions
                        .createIncompleteSubscription({
                            plan: this.plan?.value as string,
                            referralId: this.validReferral 
                                ? 'id' in this.validReferral 
                                    ? this.validReferral.id
                                    : this.validReferral.objectId
                                : '',
                            impactClickId: this.impactClickId,
                        })
                    if (clientSecret && subscriptionId) {
                        if (this.paymentMethod?.value) {
                            const { error } = await stripe.confirmPayment({
                                clientSecret: clientSecret,
                                confirmParams: {
                                    return_url: `${window.location.href}`,
                                    ['payment_method' as 'receipt_email']: this.paymentMethod?.value,
                                },
                                redirect: 'if_required',
                            })
                            if (error && error.message) {
                                // show error message
                                this.errors.push(error.message)
                                this.isPaymentBeingProcessed = false
                                // if we have error change readOnly: false so user can edit
                                paymentStripeEl?.update({ readOnly: false })
                                stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                throw new Error(error.message)
                            } else {
                                try {
                                    await subscriptionModule.actions.activateSubscription({
                                        subscriptionId: subscriptionId,
                                        plan: this.planSubscriptionName,
                                    })
                                } catch {
                                    this.errors.push('Unable to process payment. Please try again.')
                                    this.isPaymentBeingProcessed = false
                                    paymentStripeEl?.update({ readOnly: false })
                                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                    throw new Error('Unable to process payment. Please try again.')
                                }
                            }
                        }

                        if (!this.paymentMethod?.value) {
                            await elements.submit()
                            const { error } = await stripe.confirmPayment({
                                elements: isProxy(elements) ? toRaw(elements) : elements,
                                clientSecret: clientSecret,
                                confirmParams: {
                                    return_url: `${window.location.href}`,
                                },
                                redirect: 'if_required',
                            })

                            if (error && error.message) {
                                // show error message
                                this.errors.push(error.message)
                                this.isPaymentBeingProcessed = false
                                paymentStripeEl?.update({ readOnly: false })
                                stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                throw new Error(error.message)
                            } else {
                                try {
                                    await subscriptionModule.actions.activateSubscription({
                                        subscriptionId: subscriptionId,
                                        plan: this.planSubscriptionName,
                                    })
                                } catch {
                                    this.errors.push('Unable to process payment. Please try again.')
                                    this.isPaymentBeingProcessed = false
                                    paymentStripeEl?.update({ readOnly: false })
                                    stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                                    throw new Error('Unable to process payment. Please try again.')
                                }
                            }
                        }

                    } else {
                        this.errors.push('Upgrade confirm payment processing error')
                        this.isPaymentBeingProcessed = false
                        paymentStripeEl?.update({ readOnly: false })
                        stripeModule.getters.getElements()?.update({ appearance: enabledAppearance })
                        throw new Error('Upgrade confirm payment processing error')
                    }

                    this.emitClose()
                }
                await analyticsModule.actions.trackEvent('Upgrade_Viewed', {
                    result: 'Subscribed',
                    ...(this.examMetadata && { exam: this.examMetadata.nativeAppName }),
                })
                if (this.$route.name === 'level-up-intro') {
                    await analyticsModule.actions.sprigTrack({
                        eventName: 'Level_Up_Upgrade',
                    })
                }
                this.isPaymentBeingProcessed = false
            }
        }
    }

    async submitAppleGooglePayment (successfulTransaction: boolean) {
        if (this.isPaymentBeingProcessed) {
            return
        }

        this.isPaymentBeingProcessed = true

        // make sure confirm and activate payment was successful
        if (successfulTransaction) {
            await analyticsModule.actions.trackEvent('Upgrade_Viewed', {
                result: 'Subscribed',
                ...(this.examMetadata && { exam: this.examMetadata?.nativeAppName }),
            })
            if (this.$route.name === 'level-up-intro') {
                await analyticsModule.actions.sprigTrack({
                    eventName: 'Level_Up_Upgrade',
                })
            }

            this.amplitudeUpgradeViewed()
        } else {
            // if it was not a successful payment
            this.isPaymentBeingProcessed = false
            throw new Error('Unable to process payment. Please try a different payment method.')
        }

        this.isPaymentBeingProcessed = false
    }

    async cancelUpgrade () {
        await analyticsModule.actions.trackEvent('Upgrade_Viewed', {
            result: 'Free',
            ...(this.examMetadata && { exam: this.examMetadata?.nativeAppName }),
        })

        if (this.validReferral || this.referralError) {
            referralModule.actions.trackHasSeenReferralUpgradePanel()
        }

        this.emitClose()
    }

    addPaymentMethod () {
        this.showAddPaymentMethodSidePanel = true
    }

    updatePaymentParams (params: {
        addressEl: StripeElement | null | void
        cardElCompleted: boolean
        addressElCompleted: boolean
    }) {
        this.addressEl = params.addressEl
        this.cardElCompleted = params.cardElCompleted
        this.addressElCompleted = params.addressElCompleted

        if (this.cardElCompleted && this.addressElCompleted) {
            this.paymentFieldsCompleted = true
        }
    }

    isAppleGooglePayAvailable (params: {
        available: boolean
    }) {
        this.appleGooglePayAvailable = params.available
    }

    openDropdown (isOpen: boolean) {
        this.isStateDropdownOpen = isOpen
        this.$nextTick(() => {
            const modalContainer = 
                document.querySelector('.upgrade__sidepanel .uikit-side-panel__content') as HTMLElement
            modalContainer.scrollTop = modalContainer.scrollHeight
        })
    }

    agreedChanged (agreed: boolean) {
        this.agreeDisabled = agreed
    }

    getNumOfSubscriptionDays () {
        if (this.planInfo?.interval === 'month') {
            return this.planInfo.intervalCount * 30
        }

        if (this.planInfo?.interval === 'year') {
            return 365
        }

        return 0
    }
    getQuizModeScreenName () {
        if (this.quizMode === 'missed') {
            return 'Missed Quiz'
        } else if (this.quizMode === 'weakest') {
            return 'Weakest Quiz'
        }

        return null
    }
    amplitudeUpgradeViewed () {
        const priorScreenName = this.getQuizModeScreenName() || analyticsModule.getters.getScreenName(this.$route)
        const didSubscribe = !!subscriptionModule.getters.getSubscriptionForExamId()
        const numOfSubscriptionDays = didSubscribe && !this.licenseKey ? this.getNumOfSubscriptionDays() : 0

        analyticsModule.actions.amplitudeTrack('Upgrade Viewed', {
            bundleId: this.bundle?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.version,
            examGuid: this.examMetadata?.examGuid,
            organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
            userDomain: userModule.getters.getUserDomain() || undefined,
            upgradeDidSubscribe: this.licenseKey ? false : didSubscribe,
            upgradePriorScreen: priorScreenName,
            upgradeSubscriptionDays: numOfSubscriptionDays,
            upgradeLicenseCodeUsed: this.licenseKey || undefined,
        })
    }

    async toggleCompareView () {
        if (!this.hasExpandedCompareTable) {
            // check if the user has seen the compare before
            await userModule.actions.updateWebConfig({
                hasViewedCompareTable: true,
            })

            await analyticsModule.actions.trackEvent('Choose_Plan_Table_Expanded')
        }

        this.showCompare = !this.showCompare
    }

    @Watch('plan')
    updatePlanInfo () {
        if (this.planInfo) {
            if (this.planInfo.amount) {
                this.planAmount = this.planInfo.amount
            }
            this.planSubscriptionName = this.subscriptionName             
        }
    }

    @Watch('cardElCompleted')
    updateErrorsWithCardChange (newVal: boolean, oldVal: boolean) {
        if (!oldVal && newVal && this.errors.length) {
            this.errors = []
        }
    }

    @Emit('close')
    emitClose () {
        this.amplitudeUpgradeViewed()
        return true
    }

    @Emit('error')
    emitError () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.upgrade {
    position: absolute;
    color: $brand-black;

    &--full-height {
        top: 0;
        height: 100%;

        @include breakpoint(black-bear) {
            height: calc(100% - 10px);
        }
    }

    &__banner {
        margin: -12px -12px 36px;

        @include breakpoint(black-bear) {
            margin: -6px -12px 30px;
        }
    }

    &__error {
        margin: -12px;
        max-width: 396px;
    }

    &__payment-label {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 8px;

        &--premium-referral-error {
            margin-top: 48px;
        }
    }

    &__upgrade-info {
        display: flex;
        align-items: start;
        width: 310px;
        margin-bottom: 17px;
    }

    &__upgrade-message {
        color: $brand-black;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin-left: 10px;
        margin-top: 6px;

        &--dark {
            color: $fog;
        }
    }

    &__label {
        color: $brand-black;
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        margin-bottom: 26px;

        &--premium-referral-error {
            margin-top: 48px;
        }

        &--license-code-error {
            margin-top: 48px;
        }

        &--dark {
            color: $white;
        }
    }

    &__p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 7px;

        a {
            font-weight: 500;
        }
    }

    &__compare {
        margin-bottom: 35px;
    }

    &__compare-link {
        svg {
            width: 10px;
            margin-left: 2px;
        }

        &--open {
            margin-bottom: 12px;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__compare-table {
        margin: 0 -28px;

        @include breakpoint(black-bear) {
            margin: 0 -24px;
        }
    }

    &__plan,
    &__wallet {
        margin: 0 -12px 5px;

        :deep(.uikit-select__input-container) {
            width: 95%;
            margin-left: 10px;
        }
    }

    &__fields,
    &__input {
        margin: 0 -12px;

        &--state-open {
            padding-bottom: 180px;
        }
    }

    &__input {
        margin-bottom: 23px;
    }

    &__renew-date {
        margin-left: 2px;
        font-size: 12px;
    }

    &__apple-google-pay {
        margin: 16px 0 16px 0;
    }

    &__license-info {
        font-size: 14px;
        line-height: 18px;
        margin-top: -14px;
        color: $ash;
        max-width: 367px;
    }

    &__pm-options {
        margin-top: 12px;
        margin-bottom: 24px;
    }
}
</style>